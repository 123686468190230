import { ProductStage, ProductType, ProductV3 } from '../../../../../api';
import cn from 'classnames';
import { TaggableField, TaggableFieldsContainer } from '../TaggableFields';
import { FieldProps, useFormikContext } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useControlEvents } from '../../../../../hooks/useControlEvents';
import { useRef } from 'react';

export const Step1 = () => {
  const formik = useFormikContext<ProductV3>();
  const controlEvents = useControlEvents();
  const initialProductType = useRef(formik.values.productType);

  return (
    <TaggableFieldsContainer>
      <div className='flex flex-col gap-y-6'>
        <div className='flex flex-col gap-y-3'>
          <div className='text-black font-semibold text-xl'>Get started</div>
          <div className='text-base'>
            Before creating your product life cycle graph, we need to know a bit more about what type of product you’re creating.
          </div>
        </div>
        <div className='grid grid-cols-2 gap-x-16'>
          <div className='flex flex-col gap-y-4'>
            <div>Please specify if your product is already produced or if it’s in development.</div>
            <div>
              <div className='flex flex-col justify-between gap-16'>
                <TaggableField name='stage'>
                  {(model: FieldProps<ProductStage>) => (
                    <div className='grid grid-cols-2 gap-3'>
                      {[
                        { value: ProductStage.Production, label: 'In production' },
                        { value: ProductStage.Development, label: 'In development' },
                      ].map(({ value, label }) => (
                        <button
                          key={value}
                          type='button'
                          className={cn(
                            'flex justify-center items-center text-zinc-800 font-semibold rounded-lg border px-5 py-3',
                            model.field.value === value ? 'border-brand bg-slate-50' : 'border-zinc-300 bg-white',
                          )}
                          onClick={() => {
                            controlEvents!.touched(model);
                            formik.setFieldValue(model.field.name, value);
                          }}
                        >
                          {label}
                        </button>
                      ))}
                    </div>
                  )}
                </TaggableField>
              </div>
            </div>
          </div>
          <div className='flex flex-col gap-y-4'>
            <div>Is your product sold to consumers, sold to other companies or used internally?</div>
            <TaggableField name='productType'>
              {(model: FieldProps<ProductType>) => (
                <>
                  <div className='flex flex-col gap-3'>
                    {[
                      { value: ProductType.Final, label: 'Final product', text: 'Products sold to consumers.' },
                      {
                        value: ProductType.Intermediate,
                        label: 'Intermediate product',
                        text: 'Products sold for further processing.',
                      },
                      {
                        value: ProductType.Internal,
                        label: 'Internal product (not sold)',
                        text: 'Products made internally for use as input to other products. These are shared across all workspaces, meaning they can be used by products across the organisation.',
                      },
                    ].map(({ value, label, text }) => (
                      <button
                        key={value}
                        type='button'
                        className={cn(
                          'text-zinc-800 rounded-lg border px-5 py-3',
                          model.field.value === value ? 'border-brand bg-slate-50' : 'border-zinc-300 bg-white',
                        )}
                        onClick={() => {
                          controlEvents!.touched(model);
                          formik.setFieldValue(model.field.name, value);
                        }}
                      >
                        <div className='font-semibold'>{label}</div>
                        <div>{text}</div>
                      </button>
                    ))}
                  </div>
                  {initialProductType.current !== model.field.value && (
                    <div className='flex items-center gap-2 bg-amber-50 border-amber-400 rounded-lg border p-2'>
                      <FontAwesomeIcon className='text-amber-400' size='lg' icon={regular('triangle-exclamation')} />
                      Changing the product type may result in automatic deletion of parts of the original product graph to fit with the
                      requirements of the selected type
                    </div>
                  )}
                </>
              )}
            </TaggableField>
          </div>
        </div>
      </div>
    </TaggableFieldsContainer>
  );
};
