import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { light, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Menu } from '../../components/Menu';
import { Tooltip } from '../../components/Tooltip';

import {
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFocusTrap,
  useInteractions,
  useRole,
} from '@floating-ui/react-dom-interactions';
import cn from 'classnames';
import { clearAuthentication } from '../../api';
import { useProfile } from '../../hooks/useProfile';
import { useAppRoutes } from '../../hooks/useAppRoutes';

interface NavigationItem {
  path?: string;
  label: string;
  icon: IconDefinition;
}

export const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { routes } = useAppRoutes();

  return (
    <div className='z-50 print:hidden flex shrink-0 justify-center w-full h-20 bg-brandDarkPurple3'>
      <div className='flex justify-between max-w-screen-xl w-full px-12'>
        <div className='flex items-center'>
          <NavLink to='/' className='focus-visible:ring-white/40'>
            <svg width='128' height='34' viewBox='0 0 128 34'>
              <path
                d='M0 10.7342L1.28496 9.48647C2.27243 10.5979 3.2971 11.0648 4.8093 11.0648C6.3215 11.0648 7.20568 10.4409 7.20568 9.346C7.20568 8.4081 6.66856 7.94122 5.00349 7.52392L3.92924 7.28015C1.47502 6.70997 0.347063 5.68531 0.347063 3.89629C0.347063 1.74367 1.99561 0.425659 4.63577 0.425659C6.38761 0.425659 7.82957 1.01649 8.94099 2.24774L7.65604 3.49551C6.68509 2.42127 5.83396 2.09073 4.47876 2.09073C3.12356 2.09073 2.25591 2.71462 2.25591 3.79299C2.25591 4.6937 2.86327 5.16472 4.40852 5.54483L5.48276 5.7886C8.03202 6.39596 9.12692 7.42062 9.12692 9.25923C9.12692 11.3747 7.42466 12.7299 4.77211 12.7299C2.75997 12.7299 1.05771 12.0357 0 10.7342Z'
                fill='white'
              />
              <path
                d='M15.498 7.6975V0.599243H17.4234V7.50744C17.4234 9.7303 18.4646 10.945 20.4065 10.9615C22.3318 10.945 23.34 9.77988 23.34 7.57768V0.599243H25.2653V7.62726C25.2653 10.8706 23.4928 12.7299 20.3693 12.7464C17.2788 12.7299 15.4939 10.8706 15.4939 7.6975H15.498Z'
                fill='white'
              />
              <path
                d='M31.4629 10.7342L32.7478 9.48647C33.7353 10.5979 34.76 11.0648 36.2722 11.0648C37.7844 11.0648 38.6686 10.4409 38.6686 9.346C38.6686 8.4081 38.1314 7.94122 36.4664 7.52392L35.3921 7.28015C32.9462 6.70584 31.8182 5.68531 31.8182 3.89629C31.8182 1.74367 33.4667 0.425659 36.1069 0.425659C37.8587 0.425659 39.3007 1.01649 40.4121 2.24774L39.1272 3.49551C38.1562 2.42127 37.3051 2.09073 35.9499 2.09073C34.5947 2.09073 33.727 2.71462 33.727 3.79299C33.727 4.6937 34.3344 5.16472 35.8797 5.54483L36.9539 5.7886C39.5032 6.39596 40.5981 7.42062 40.5981 9.25923C40.5981 11.3747 38.8958 12.7299 36.2432 12.7299C34.2311 12.7299 32.5289 12.0357 31.4711 10.7342H31.4629Z'
                fill='white'
              />
              <path d='M49.4936 12.577V2.37174H45.6387V0.599243H55.2532V2.37174H51.3983V12.577H49.4895H49.4936Z' fill='white' />
              <path
                d='M68.2515 12.577L67.1236 9.52371H62.0209L60.8764 12.577H58.8436L63.4959 0.599243H65.6651L70.3008 12.577H68.2515ZM62.6448 7.83798H66.4997L64.8181 3.30964L64.5743 2.59899C64.4875 2.89234 64.4338 3.04934 64.3471 3.29311L62.6448 7.84211V7.83798Z'
                fill='white'
              />
              <path d='M76.2339 12.577V0.599243H78.1593V12.577H76.2339Z' fill='white' />
              <path
                d='M85.2741 12.577V0.599243H87.5135L93.1533 9.29647C93.2937 9.48653 93.397 9.69724 93.5168 9.90383C93.5003 9.69724 93.5003 9.50305 93.5003 9.29647V0.599243H95.3761V12.577H93.1367L87.497 3.90047C87.3565 3.69388 87.2532 3.4997 87.1334 3.29311C87.1499 3.4997 87.1499 3.69388 87.1499 3.90047V12.577H85.2741Z'
                fill='white'
              />
              <path
                d='M102.487 12.577V0.599243H110.713V2.31803H104.412V5.61512H109.792V7.33391H104.412V10.8582H110.767V12.577H102.487Z'
                fill='white'
              />
              <path
                d='M117.22 12.577V0.599243H121.385C125.203 0.599243 127.946 2.85515 127.946 6.55302C127.946 10.2509 125.186 12.577 121.385 12.577H117.22ZM119.146 10.8045H121.369C124.058 10.8045 125.967 9.24276 125.967 6.55302C125.967 3.86328 124.075 2.37174 121.369 2.37174H119.146V10.8045Z'
                fill='white'
              />
              <path d='M0.813934 33.4008V21.423H2.74344V33.4008H0.813934Z' fill='white' />
              <path
                d='M9.85823 33.4008V21.423H12.3249L15.7955 28.3146L19.2496 21.423H21.6955V33.4008H19.8198V24.7242C19.8198 24.5176 19.8197 24.3234 19.8363 24.1334C19.733 24.3234 19.6297 24.5341 19.5223 24.7407L16.659 30.4507H14.9072L12.0274 24.7077L11.7134 24.1334C11.7299 24.3234 11.7299 24.5341 11.7299 24.7407V33.4008H9.8541H9.85823Z'
                fill='white'
              />
              <path
                d='M37.9166 25.3646C37.9166 27.8106 36.0615 29.4261 33.2808 29.4261H30.7316V33.4008H28.8062V21.423H33.4213C36.1813 21.423 37.9166 22.9517 37.9166 25.3646ZM33.2147 27.6908C34.8467 27.6908 35.9375 26.79 35.9375 25.4349C35.9375 24.0135 34.9128 23.1624 33.2478 23.1624H30.7316V27.6908H33.2147Z'
                fill='white'
              />
              <path
                d='M51.609 33.4008L50.4811 30.3474H45.3784L44.234 33.4008H42.2012L46.8535 21.423H49.0226L53.6584 33.4008H51.609ZM46.0023 28.6617H49.8572L48.1715 24.1334L47.9277 23.4227C47.8409 23.7161 47.7872 23.8731 47.7005 24.1168L45.9982 28.6658L46.0023 28.6617Z'
                fill='white'
              />
              <path
                d='M58.323 27.4098C58.323 23.7491 60.9425 21.2494 64.4338 21.2494C66.3096 21.2494 67.9912 22.0138 69.1564 23.3855L67.7144 24.5837C66.9335 23.5962 65.7188 23.0384 64.4338 23.0384C62.0581 23.0384 60.3187 24.7572 60.3187 27.4139C60.3187 30.0706 62.0374 31.7894 64.4338 31.7894C65.8551 31.7894 66.9666 31.2522 67.8342 30.1904L69.2555 31.3886C68.1276 32.7934 66.4253 33.5742 64.4297 33.5742C60.9425 33.5742 58.3189 31.058 58.3189 27.4139L58.323 27.4098Z'
                fill='white'
              />
              <path d='M78.1593 33.4008V23.1955H74.3045V21.423H83.9189V23.1955H80.064V33.4008H78.1552H78.1593Z' fill='white' />
              <path
                d='M121.91 33.2685C125.273 33.2685 128 30.5419 128 27.1784C128 23.8149 125.273 21.0883 121.91 21.0883C118.546 21.0883 115.82 23.8149 115.82 27.1784C115.82 30.5419 118.546 33.2685 121.91 33.2685Z'
                fill='#D6FF00'
              />
              <path
                d='M103.115 31.434C105.465 31.434 107.37 29.5287 107.37 27.1784C107.37 24.828 105.465 22.9227 103.115 22.9227C100.764 22.9227 98.8591 24.828 98.8591 27.1784C98.8591 29.5287 100.764 31.434 103.115 31.434Z'
                fill='#D6FF00'
              />
            </svg>
          </NavLink>
        </div>
        <div className='flex justify-center items-center gap-7 flex-1 text-white'>
          {!['/prototype'].includes(location.pathname) &&
            [
              { alias: 'home', path: routes.root, label: 'Home dashboard', icon: regular('house') },
              { alias: 'products', path: `${routes.products.list}`, label: 'Products', icon: regular('box-circle-check') },
              { alias: 'manufacturing', label: 'Volume', icon: regular('boxes-stacked') },
              { alias: 'manage', label: 'Manage', icon: regular('industry-windows') },
            ].map((item) =>
              item.path ? (
                <NavLink
                  key={item.path}
                  to={item.path}
                  className={cn(
                    'flex items-center gap-2 shrink-0 py-1.5 text-sm font-semibold border-b border-transparent',
                    (item.alias === 'home' && location.pathname.endsWith(routes.root)) || location.pathname.includes(item.alias)
                      ? 'border-b-brandLime'
                      : 'border-b-transparent',
                  )}
                >
                  <FontAwesomeIcon icon={item.icon} />
                  {item.label}
                </NavLink>
              ) : (
                (() => {
                  const menu = (category: NavigationItem & { alias: string }, subCategories: NavigationItem[]) => (
                    <Menu
                      key={category.label}
                      placement='bottom'
                      items={subCategories.map((item) => ({
                        ...item,
                        onClick: () => navigate(item.path!),
                      }))}
                    >
                      {() => (
                        <button
                          type='button'
                          className={cn('flex items-center gap-2 shrink-0 py-1.5 text-sm font-semibold border-b border-transparent', {
                            'border-b-brandLime': location.pathname.includes(category.alias),
                          })}
                        >
                          <FontAwesomeIcon icon={item.icon} />
                          {item.label}
                        </button>
                      )}
                    </Menu>
                  );

                  if (item.label === 'Manage') {
                    const subCategories = [
                      {
                        icon: light('user'),
                        label: 'Providers & Suppliers',
                        path: routes.manage.suppliers.root('all'),
                      },
                      {
                        icon: light('location-dot'),
                        label: 'Locations & Facilities',
                        path: routes.manage.facilities.root('all'),
                      },
                      {
                        icon: light('arrow-right-to-line'),
                        label: 'Mappings',
                        path: routes.manage.mappings.root('all'),
                      },
                    ];

                    return menu(item, subCategories);
                  }

                  if (item.label === 'Volume') {
                    const subCategories = [
                      {
                        icon: light('chart-line-down'),
                        label: 'Progress tracking',
                        path: '/manufacturing/progress-tracking',
                      },
                      {
                        icon: light('flag-checkered'),
                        label: 'Baseline',
                        path: '/manufacturing/baseline/production/overview',
                      },
                      {
                        icon: light('bullseye-arrow'),
                        label: 'Targets',
                        path: '/manufacturing/targets',
                      },
                      {
                        icon: light('clock-rotate-left'),
                        label: 'Historical reports',
                        path: '/manufacturing/historical-reports',
                      },
                      {
                        icon: light('magnifying-glass-chart'),
                        label: 'Forecasts',
                        path: '/manufacturing/forecasts',
                      },
                    ].map((subCategory) => ({
                      ...subCategory,
                      path: routes.root + subCategory.path,
                    }));

                    return menu(item, subCategories);
                  }
                })()
              ),
            )}
        </div>
        <div className='flex gap-2 items-center'>
          <Tooltip content='Methodology'>
            <NavLink
              to={routes.methodology}
              className={cn(
                'flex justify-center items-center size-9 rounded-full',
                'focus-visible:ring-white/40',
                location.pathname.endsWith(routes.methodology) ? 'text-brandDarkPurple3 bg-white' : 'text-white',
              )}
            >
              <FontAwesomeIcon icon={solid('memo-circle-info')} size='lg' />
            </NavLink>
          </Tooltip>
          <Menu
            placement='bottom-end'
            items={[
              {
                label: 'FAQs',
                onClick: () => navigate(routes.faq),
              },
              {
                label: 'Help',
                onClick: () => navigate(routes.help),
              },
            ]}
          >
            {({ open }) => (
              <button
                type='button'
                className={cn(
                  'flex justify-center items-center size-9 rounded-full',
                  'focus-visible:ring-white/40',
                  open || ['/faq', '/help'].some((path) => location.pathname.includes(path))
                    ? 'text-brandDarkPurple3 bg-white'
                    : 'text-white',
                )}
              >
                <FontAwesomeIcon icon={regular('question-circle')} size='lg' />
              </button>
            )}
          </Menu>
          <Profile />
        </div>
      </div>
    </div>
  );
};

const Profile = () => {
  const navigate = useNavigate();
  const { routes } = useAppRoutes();
  const [open, setOpen] = useState(false);
  const profile = useProfile();

  const { x, y, reference, floating, strategy, context } = useFloating({
    placement: 'bottom-end',
    open,
    onOpenChange: setOpen,
    middleware: [offset({ mainAxis: 8, crossAxis: 8 }), flip(), shift()],
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useFocusTrap(context, {
      modal: false,
      order: ['reference', 'content'],
    }),
    useRole(context, { role: 'menu' }),
    useDismiss(context),
  ]);

  const itemStyles = 'flex gap-2 items-center rounded-lg pl-2 pr-3 text-sm';
  const iconWidth = 'size-6';

  const renderLogo = (url?: string) => (
    <div className={cn(iconWidth, 'flex rounded-lg overflow-hidden')}>
      {url ? (
        <img src={url} alt='Logo' />
      ) : (
        <div className='w-full h-full flex justify-center items-center bg-[#E8EAF5] rounded-lg'>
          <FontAwesomeIcon icon={regular('building')} />
        </div>
      )}
    </div>
  );

  return (
    <>
      <div className='bg-white rounded-md'>
        <button
          type='button'
          className='bg-brandDarkPurple3 hover:bg-brandDarkPurple3/80 pr-2 rounded-md flex gap-x-1 justify-center items-center truncate max-w-96 p-0.5'
          {...getReferenceProps({ ref: reference })}
        >
          <div className='flex shrink-0 justify-center items-center size-9 rounded-full overflow-hidden'>
            {profile.selectedWorkspace.logoUrl ? (
              <img src={profile.selectedWorkspace.logoUrl} alt='Logo' />
            ) : (
              <FontAwesomeIcon icon={solid('building')} size='lg' className='text-white' />
            )}
          </div>
          <div title={profile.selectedWorkspace.name} className='truncate font-semibold text-white'>
            {profile.selectedWorkspace.name}
          </div>
        </button>
      </div>
      <AnimatePresence>
        {open && (
          <motion.div
            transition={{ type: 'spring', bounce: 0.5, duration: 0.5 }}
            initial={{ opacity: 0, scale: 0.99 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.99 }}
            {...getFloatingProps({
              ref: floating,
              style: {
                position: strategy,
                left: x ?? '',
                top: y ?? '',
              },
            })}
            className='antialiased shadow-xl bg-white border rounded-lg p-2 pt-3 pb-4 flex flex-col gap-2.5 text-zinc-600'
          >
            <div className={itemStyles}>
              {renderLogo(profile.selectedWorkspace.logoUrl)}
              <div className='font-semibold text-zinc-700'>{profile.selectedWorkspace.name}'s Workspace</div>
            </div>
            <NavLink
              to={routes.workspace}
              className={cn(itemStyles, 'leading-none hover:underline hover:text-brand')}
              onClick={() => setOpen(false)}
            >
              <div className={iconWidth} />
              <div>Settings</div>
            </NavLink>
            {profile.workspaces.length > 1 && (
              <>
                <div className='shrink-0 h-px bg-zinc-300' />
                <div className='uppercase text-xs font-semibold text-zinc-500 ml-2'>Switch Workspace</div>
                <div className='flex flex-col gap-1'>
                  {profile.workspaces
                    .filter(({ workspaceSid }) => workspaceSid !== profile.selectedWorkspace.workspaceSid)
                    .map((workspace) => (
                      <button
                        key={workspace.workspaceSid}
                        type='button'
                        className={cn(
                          itemStyles,
                          'py-1',
                          workspace.workspaceSid === profile.selectedWorkspace.workspaceSid ? 'bg-slate-100' : 'hover:bg-slate-50',
                        )}
                        onClick={() => {
                          profile.onWorkspaceChanged(workspace.workspaceSid);
                        }}
                      >
                        {renderLogo(workspace.logoUrl)}
                        <div>{workspace.name}</div>
                      </button>
                    ))}
                </div>
              </>
            )}
            <div className='shrink-0 h-px bg-zinc-300' />
            <div className={itemStyles}>
              <FontAwesomeIcon icon={solid('user-circle')} className='text-[#c2b0f8] text-2xl' />
              <div className='font-semibold text-zinc-700'>{profile.user.name}</div>
            </div>
            <NavLink
              to={`${routes.root}/account`}
              className={cn(itemStyles, 'leading-none hover:underline hover:text-brand')}
              onClick={() => setOpen(false)}
            >
              <div className={iconWidth} />
              <div>Account settings</div>
            </NavLink>
            <button
              type='button'
              className={cn(itemStyles, 'leading-none hover:underline hover:text-brand')}
              onClick={() => {
                sessionStorage.clear();
                clearAuthentication();
                navigate('/');
              }}
            >
              <div className={iconWidth} />
              <div>Sign Out</div>
            </button>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
