import { light, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { Field, FieldProps, Form, Formik, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router';
import { NavLink, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { authenticate, AuthenticateError, resetPassword, signup } from '../api';
import { Input } from '../components/Input';
import { Modal } from '../components/Modal';
import { LoginFrame } from './LoginFrame';
import { TempPasswordChange } from './TempPasswordChange';

interface Props {
  trial?: boolean;
}

export const Login = (props: Props) => {
  const navigate = useNavigate();
  const [authenticateError, setAuthenticateError] = useState<{ errorCode: AuthenticateError; message: string }>();
  const [formValid, setFormValid] = useState(true);
  const [showTempPasswordFlow, setShowTempPasswordFlow] = useState(false);
  const redirectLink = localStorage.getItem('redirect');
  const workspaceId = redirectLink?.split('/').find((part) => part.startsWith('workspace.'));

  return (
    <LoginFrame>
      <Formik
        initialValues={{
          username: '',
          password: '',
        }}
        validationSchema={yup.object().shape({
          username: yup.string().email().required(),
          password: yup.string().required(),
        })}
        validateOnBlur={false}
        validateOnChange={!formValid}
        onSubmit={(values, { setSubmitting }) => {
          authenticate(values).call(
            (response) => {
              if (response.errorCode) {
                setSubmitting(false);
                if (response.errorCode === AuthenticateError.NewPasswordRequired) {
                  setShowTempPasswordFlow(true);
                } else {
                  setAuthenticateError({ errorCode: response.errorCode!, message: response.message! });
                }
              } else {
                navigate('/workspaces');
              }
            },
            () => setSubmitting(false),
          );
        }}
      >
        {!showTempPasswordFlow && (
          <Main
            setFormValid={setFormValid}
            authenticateError={authenticateError}
            clearAuthenticateError={() => setAuthenticateError(undefined)}
          />
        )}
      </Formik>
      <TempPasswordChange show={showTempPasswordFlow} />
      <SignUp show={props.trial} />
      {!showTempPasswordFlow && (
        <div className='flex flex-col items-center text-center gap-4'>
          <div className='text-black'>
            <span>Not a customer yet? </span>
            <a href='https://sustained.com/get-started' rel='noreferrer' target='_blank' className='font-semibold underline rounded-md'>
              Try it now for free
            </a>
          </div>
          <div className='w-80 flex flex-col gap-y-6 bg-[#F7F2FF] rounded-3xl p-6'>
            <div className='flex flex-col gap-2 text-[#4100BF]'>
              {['Full access for 2 weeks', "Up to 5 product SKU's", 'Up to 2 models per SKU'].map((item) => (
                <div key={item}>{item}</div>
              ))}
            </div>
          </div>

          {(() => {
            const subject = workspaceId ? `Request access to workspace: ${workspaceId}` : 'Request access to existing customer account';
            return (
              <div className='text-zinc-700'>
                Want to request access to an existing Sustained account?{' '}
                <a className='hover:text-brand underline text-black font-semibold' href={`mailto:help@sustained.com?subject=${subject}`}>
                  Contact us now
                </a>
              </div>
            );
          })()}
        </div>
      )}
    </LoginFrame>
  );
};

const Main = (props: {
  setFormValid: (value: boolean) => void;
  authenticateError?: { errorCode: AuthenticateError; message: string };
  clearAuthenticateError: () => void;
}) => {
  const formik = useFormikContext<{ username: string; password: string }>();

  useEffect(() => {
    props.setFormValid(formik.isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(props.clearAuthenticateError, [formik.values]);

  return (
    <Form className='flex flex-col items-center gap-4'>
      <Helmet title='Sign in' />
      <div className='text-2xl font-semibold text-black pl-4'>Sign in</div>
      <Field name='username'>
        {(model: FieldProps<string>) => (
          <div className='w-80 flex flex-col gap-1.5'>
            <div className='text-dark ml-4 text-sm'>Email</div>
            <div className='flex flex-col flex-1'>
              <Input autoFocus model={model} placeholder='name@company.com' />
            </div>
          </div>
        )}
      </Field>
      <Field name='password'>
        {(model: FieldProps<string>) => (
          <div className='w-80 flex flex-col gap-1.5'>
            <div className='text-dark ml-4 text-sm'>Password</div>
            <div className='flex flex-col flex-1'>
              <Input password model={model} placeholder='Type in your password…' />
            </div>
          </div>
        )}
      </Field>
      {props.authenticateError?.errorCode && (
        <div className='w-80 flex flex-col gap-1 pl-4 -mt-2 text-xs text-red-500'>
          {props.authenticateError.errorCode === AuthenticateError.InvalidCredentials ? (
            <>
              <div>Wrong email or password…</div>
              <div>Please try again.</div>
            </>
          ) : props.authenticateError.errorCode === AuthenticateError.UserUnconfirmed ? (
            <>
              <div>
                This email address has not been verified… Please click the link in the email received (check the junk folder as well), then
                try again.
              </div>
              <div>
                Didn’t receive an email?{' '}
                <NavLink to='/forgot-password' className='underline'>
                  Try resetting your password
                </NavLink>
              </div>
            </>
          ) : (
            <div>{props.authenticateError.message}</div>
          )}
        </div>
      )}
      <NavLink to='/forgot-password' className='w-80 flex flex-col pl-4 -mt-2 text-xs text-brandDark underline'>
        <div>Forgot your password?</div>
      </NavLink>
      <button
        type='submit'
        disabled={formik.isSubmitting || formik.isValidating}
        className={cn(
          'mt-4 flex justify-center w-48 bg-brand rounded-full py-2 text-white font-semibold',
          '[&:active:not(:disabled)]:scale-95',
          'disabled:cursor-wait',
        )}
      >
        Sign in
      </button>
    </Form>
  );
};

const SignUp = (props: { show?: boolean }) => {
  const [searchParams] = useSearchParams();
  const [showSignupPassword, setShowSignupPassword] = useState(false);
  const [userAlreadyExist, setUserAlreadyExist] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [resetSent, setResetSent] = useState(false);
  const [signupStep, setSignupStep] = useState<'' | 'form' | 'thankYou'>(props.show ? 'form' : '');

  const firstName = searchParams.get('first') ?? '';
  const lastName = searchParams.get('last') ?? '';
  const email = searchParams.get('email') ?? '';
  const company = searchParams.get('company') ?? '';
  const autoFocusField = (() => {
    if (!firstName) {
      return 'firstName';
    }

    if (!lastName) {
      return 'lastName';
    }

    if (!email) {
      return 'email';
    }

    if (!company) {
      return 'company';
    }

    return 'password';
  })();

  return (
    <>
      {signupStep && (
        <Modal
          titleContent={
            <div className='text-3xl font-semibold text-center text-brand'>
              {signupStep === 'form' ? 'Start with a 2-week free trial' : 'Verify your email address'}
            </div>
          }
          formik={
            signupStep === 'form'
              ? {
                  initialValues: {
                    firstName,
                    lastName,
                    email,
                    company,
                    job: '',
                    jobInput: '',
                    industry: '',
                    industryInput: '',
                    password: '',
                    password2: '',
                  },
                  validationSchema: yup.object().shape({
                    firstName: yup.string().required(),
                    lastName: yup.string().required(),
                    email: yup.string().email().required(),
                    company: yup.string().required(),
                    job: yup.string().required(),
                    jobInput: yup.string().when('job', {
                      is: 'Other',
                      then: (schema) => schema.required(),
                      otherwise: (schema) => schema.nullable(),
                    }),
                    industry: yup.string().required(),
                    industryInput: yup.string().when('industry', {
                      is: 'Other',
                      then: (schema) => schema.required(),
                      otherwise: (schema) => schema.nullable(),
                    }),
                    password: yup
                      .string()
                      .min(16)
                      .test(
                        'pattern',
                        'pattern',
                        (value) => !!value && value !== value.toLowerCase() && value !== value.toUpperCase() && /\d/g.test(value),
                      )
                      .required(),
                    password2: yup.string().test('', 'same', function (value) {
                      return value === this.parent.password;
                    }),
                  }),
                }
              : undefined
          }
          bodyContent={
            signupStep === 'form' ? (
              <div className='flex flex-col gap-10 max-w-lg text-body'>
                <div className='flex flex-col gap-6'>
                  <div className='text-lg'>
                    Try Sustained Impact for free for two weeks,{' '}
                    <span className='text-brand'>no credit cards or billing details required</span>.
                  </div>
                  <div className='flex justify-center'>
                    <div className='flex flex-col gap-5 text-lg'>
                      {[
                        {
                          icon: light('box-circle-check'),
                          text: (
                            <>
                              Create up to <span className='text-xl font-semibold text-brand'>5</span> product SKU's
                            </>
                          ),
                        },
                        {
                          icon: light('chart-line-up'),
                          text: (
                            <>
                              Create up to <span className='text-xl font-semibold text-brand'>2</span> models per SKU
                            </>
                          ),
                        },
                      ].map((item, i) => (
                        <div key={i} className='flex items-center gap-3'>
                          <div className='flex justify-center items-center bg-brand/5 rounded-full w-12 aspect-square'>
                            <FontAwesomeIcon icon={item.icon} className='text-brand' />
                          </div>
                          <div>{item.text}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className='flex flex-col gap-6'>
                  <div className='flex gap-6'>
                    <Field name='firstName'>
                      {(model: FieldProps<string>) => (
                        <div className='flex-1 flex flex-col gap-1'>
                          <div className='font-semibold text-dark ml-4'>
                            First Name <span className='text-brand'>*</span>
                          </div>
                          <div className='flex flex-col flex-1'>
                            <Input autoFocus={autoFocusField === model.field.name} model={model} placeholder='John' />
                          </div>
                        </div>
                      )}
                    </Field>
                    <Field name='lastName'>
                      {(model: FieldProps<string>) => (
                        <div className='flex-1 flex flex-col gap-1'>
                          <div className='font-semibold text-dark ml-4'>
                            Last Name <span className='text-brand'>*</span>
                          </div>
                          <div className='flex flex-col flex-1'>
                            <Input autoFocus={autoFocusField === model.field.name} model={model} placeholder='Doe' />
                          </div>
                        </div>
                      )}
                    </Field>
                  </div>
                  <Field name='email'>
                    {(model: FieldProps<string>) => (
                      <div className='flex flex-col gap-1'>
                        <div className='font-semibold text-dark ml-4'>
                          Email <span className='text-brand'>*</span>
                        </div>
                        <div className='flex flex-col flex-1'>
                          <Input autoFocus={autoFocusField === model.field.name} model={model} placeholder='john@sustained.com' />
                        </div>
                      </div>
                    )}
                  </Field>
                  <Field name='company'>
                    {(model: FieldProps<string>) => (
                      <div className='flex flex-col gap-1'>
                        <div className='font-semibold text-dark ml-4'>
                          Company <span className='text-brand'>*</span>
                        </div>
                        <div className='flex flex-col flex-1'>
                          <Input autoFocus={autoFocusField === model.field.name} model={model} placeholder='Sustained' />
                        </div>
                      </div>
                    )}
                  </Field>
                  <Field name='job'>
                    {(model: FieldProps<string>) => (
                      <Field name='jobInput'>
                        {(inputModel: FieldProps<string>) => (
                          <div className='flex flex-col gap-1'>
                            <div className='font-semibold text-dark ml-4'>
                              What best describes your job title of function? <span className='text-brand'>*</span>
                            </div>
                            <div className='flex items-center flex-1 relative'>
                              <FontAwesomeIcon
                                icon={regular('chevron-down')}
                                className={cn('absolute right-4', model.meta.error ? 'text-f' : 'text-brand')}
                              />
                              <select
                                {...model.field}
                                className={cn(
                                  'flex-1 rounded-full border px-4 py-2 appearance-none',
                                  model.meta.error ? 'border-f' : 'border-brand',
                                )}
                              >
                                {[
                                  '',
                                  'Business development',
                                  'Data analyst',
                                  'Ecologist',
                                  'Engineering',
                                  'Environmental consultant',
                                  'Environmental educator',
                                  'Environmental scientist',
                                  'Executive leadership',
                                  'Finance',
                                  'I.T.',
                                  'Legal',
                                  'Marketing',
                                  'Operations',
                                  'Policy',
                                  'Procurement',
                                  'Project management',
                                  'Sales',
                                  'Sustainability',
                                  'Product design and development',
                                  'Other',
                                ].map((value) => (
                                  <option key={value} value={value} disabled={!value}>
                                    {value || 'Choose…'}
                                  </option>
                                ))}
                              </select>
                            </div>
                            {model.field.value === 'Other' && (
                              <div className='flex flex-col flex-1 mt-2'>
                                <Input model={inputModel} autoFocus placeholder='Please specify…' />
                              </div>
                            )}
                          </div>
                        )}
                      </Field>
                    )}
                  </Field>
                  <Field name='industry'>
                    {(model: FieldProps<string>) => (
                      <Field name='industryInput'>
                        {(inputModel: FieldProps<string>) => (
                          <div className='flex flex-col gap-1'>
                            <div className='font-semibold text-dark ml-4'>
                              What best describes your company industry? <span className='text-brand'>*</span>
                            </div>
                            <div className='flex items-center flex-1 relative'>
                              <FontAwesomeIcon
                                icon={regular('chevron-down')}
                                className={cn('absolute right-4', model.meta.error ? 'text-f' : 'text-brand')}
                              />
                              <select
                                {...model.field}
                                className={cn(
                                  'flex-1 rounded-full border px-4 py-2 appearance-none',
                                  model.meta.error ? 'border-f' : 'border-brand',
                                )}
                              >
                                {[
                                  '',
                                  'Advertising & marketing services',
                                  'Agriculture',
                                  'Energy',
                                  'Consulting',
                                  'Financial services',
                                  'Food & beverage manufacturing',
                                  'Food & beverage services',
                                  'Food & beverage retail',
                                  'Government',
                                  'Hospitality and tourism',
                                  'Legal services',
                                  'Manufacturing',
                                  'Non profit & NGO',
                                  'Property',
                                  'Retail and consumer goods',
                                  'Technology & I.T.',
                                  'Other',
                                ].map((value) => (
                                  <option key={value} value={value} disabled={!value}>
                                    {value || 'Choose…'}
                                  </option>
                                ))}
                              </select>
                            </div>
                            {model.field.value === 'Other' && (
                              <div className='flex flex-col flex-1 mt-2'>
                                <Input model={inputModel} autoFocus placeholder='Please specify…' />
                              </div>
                            )}
                          </div>
                        )}
                      </Field>
                    )}
                  </Field>
                </div>
                <div className='flex flex-col gap-6'>
                  <Field name='password'>
                    {(model: FieldProps<string>) => {
                      const value = model.field.value ?? '';
                      return (
                        <div className='flex flex-col gap-1'>
                          <div className='flex justify-between px-4'>
                            <div className='font-semibold text-dark'>
                              Password <span className='text-brand'>*</span>
                            </div>
                            <button
                              type='button'
                              className='flex items-center gap-1 rounded-md text-xs font-semibold text-light'
                              onClick={() => setShowSignupPassword((value) => !value)}
                            >
                              <FontAwesomeIcon icon={showSignupPassword ? solid('eye-slash') : solid('eye')} />
                              {showSignupPassword ? 'Hide Password' : 'Show Password'}
                            </button>
                          </div>
                          <div className='flex flex-col flex-1'>
                            <Input
                              autoFocus={autoFocusField === model.field.name}
                              model={model}
                              password={!showSignupPassword}
                              placeholder='A long and strong password'
                            />
                          </div>
                          <div className='ml-4 text-sm text-light'>
                            <span className={cn('font-semibold', { 'text-brand': value.length >= 16 })}>16 characters</span> or more with at
                            least <span className={cn('font-semibold', { 'text-brand': value !== value.toUpperCase() })}>1 lowercase</span>,{' '}
                            <span className={cn('font-semibold', { 'text-brand': value !== value.toLowerCase() })}>1 uppercase</span>,{' '}
                            <span className={cn('font-semibold', { 'text-brand': /\d/g.test(value) })}>1 number</span>.
                          </div>
                        </div>
                      );
                    }}
                  </Field>
                  <Field name='password2'>
                    {(model: FieldProps<string>) => (
                      <div className='flex flex-col gap-1'>
                        <div className='flex justify-between px-4'>
                          <div className='font-semibold text-dark'>
                            Confirm Password <span className='text-brand'>*</span>
                          </div>
                          <button
                            type='button'
                            className='flex items-center gap-1 rounded-md text-xs font-semibold text-light'
                            onClick={() => setShowSignupPassword((value) => !value)}
                          >
                            <FontAwesomeIcon icon={showSignupPassword ? solid('eye-slash') : solid('eye')} />
                            {showSignupPassword ? 'Hide Password' : 'Show Password'}
                          </button>
                        </div>
                        <div className='flex flex-col flex-1'>
                          <Input model={model} password={!showSignupPassword} placeholder='Type in the same password' />
                        </div>
                        {model.meta.error && <div className='ml-4 text-sm text-f'>Passwords don't match.</div>}
                      </div>
                    )}
                  </Field>
                  {userAlreadyExist && <div className='text-f text-center font-semibold'>This email is registered already.</div>}
                </div>
              </div>
            ) : (
              <div className='flex flex-col gap-6 text-lg max-w-lg text-body text-center'>
                <div>
                  <span className='text-brand'>Thank you</span> for registering!
                </div>
                <div>
                  To start using your trial account, all you have to do is <span className='text-brand'>confirm your email address</span> by
                  clicking on the <span className='text-brand'>link in the email</span> we sent you!
                </div>
              </div>
            )
          }
          contentBelowButtons={(values) =>
            signupStep === 'form' ? (
              <div className='flex flex-col text-center text-light'>By submitting this form, you agree to be contacted by our team.</div>
            ) : (
              <div className='flex justify-center'>
                {resetSent ? (
                  <div className='flex items-center gap-3 bg-emerald-50 text-emerald-800 pl-4 pr-6 py-3 rounded-full'>
                    <div className='bg-emerald-200 w-8 aspect-square flex justify-center items-center rounded-full'>
                      <FontAwesomeIcon icon={light('check')} />
                    </div>
                    <div>Email resent</div>
                  </div>
                ) : (
                  <button
                    type='button'
                    disabled={waiting}
                    className='rounded-md text-brand font-semibold px-6 -mt-3 disabled:cursor-wait'
                    onClick={() => {
                      setWaiting(true);
                      resetPassword(values).call({
                        ok: () => {
                          setWaiting(false);
                          setResetSent(true);
                        },
                        fail: () => {
                          setWaiting(false);
                        },
                      });
                    }}
                  >
                    Resend Email
                  </button>
                )}
              </div>
            )
          }
          waiting={waiting}
          onConfirm={(values) => {
            setUserAlreadyExist(false);

            if (signupStep === 'form') {
              setWaiting(true);
              signup({
                ...values,
                job: values.job === 'Other' ? values.jobInput : values.job,
                industry: values.industry === 'Other' ? values.industryInput : values.industry,
              }).call({
                ok: ({ errorCode }) => {
                  setWaiting(false);

                  if (errorCode === 'user-exists') {
                    setUserAlreadyExist(true);
                  } else {
                    setSignupStep('thankYou');
                  }
                },
                fail: () => {
                  setWaiting(false);
                },
              });
            } else {
              window.location.reload();
            }
          }}
          confirmLabel={signupStep === 'form' ? 'Try for free' : 'Sign In'}
          noCancel
          scrollable
          open
          onOpenChange={() => {
            setWaiting(false);
            setUserAlreadyExist(false);
            setResetSent(false);
            setSignupStep('');
          }}
        />
      )}
    </>
  );
};
