import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  cloneElement,
  CSSProperties,
  ForwardedRef,
  forwardRef,
  Fragment,
  HTMLProps,
  isValidElement,
  ReactNode,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { ModalApi } from '../../../../components/ModalV3';
import {
  FloatingOverlay,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useFocusTrap,
  useInteractions,
  useRole,
} from '@floating-ui/react-dom-interactions';
import cn from 'classnames';
import { Menu } from '../../../../components/Menu';
import { getProductModelV3, ImpactDeltaType, ModellingPayload } from '../../../../api';
import { roundToLong, simplify } from '../../shared';
import { formatPhysicalImpact } from './dataModel';
import min from 'lodash/min';
import max from 'lodash/max';

interface Props {
  modellingPayload: ModellingPayload;
}

export const ScaledToScopeImpacts = (props: Props) => {
  const [data, setData] = useState<ModellingPayload | null>(null);
  const modalRef = useRef<ModalApi>(null);

  const fetchDataWithSelectedUnit = async (unit: 'kg' | 'serving') => {
    getProductModelV3(props.modellingPayload.product.id, props.modellingPayload.model.id, unit).call({
      ok: (data) => {
        setData(data);
      },
    });
  };

  const body = () => {
    return data ? (
      <div className='text-xs flex flex-col gap-4'>
        <div className='flex gap-3'>
          <div className='flex-1 flex flex-col gap-2 px-4 py-3 bg-slate-100 border border-indigo-100 rounded-2xl'>
            <div className='font-semibold'>Original impact</div>
            <div title={roundToLong(data.impact.originalImpactPoints)} className='inline-flex items-center gap-1.5'>
              <span className='text-zinc-900 text-base'>{simplify(data.impact.originalImpactPoints)}</span>
              <span className='uppercase text-zinc-500 tracking-widest text-[10px]'>Impact points</span>
            </div>
          </div>
          <div className='flex-1 flex flex-col gap-2 px-4 py-3 border border-zinc-200 rounded-2xl'>
            <div className='font-semibold'>Modelled impact</div>
            <div title={roundToLong(data.impact.modelImpactPoints)} className='inline-flex items-center gap-1.5'>
              <span className='text-zinc-900 text-base'>{simplify(data.impact.modelImpactPoints)}</span>
              <span className='uppercase text-zinc-500 tracking-widest text-[10px]'>Impact points</span>
            </div>
          </div>
          <div
            className={cn(
              'flex justify-center items-center gap-1.5 rounded-2xl text-base px-4',
              {
                [ImpactDeltaType.Higher]: 'text-red-500 bg-red-50',
                [ImpactDeltaType.Lower]: 'text-emerald-700 bg-emerald-50',
                [ImpactDeltaType.Zero]: 'text-zinc-600 bg-zinc-100',
              }[data.impact.impactDelta.type],
            )}
          >
            <div className='flex gap-1 items-center'>
              <div>{data.impact.impactDelta.display}</div>
            </div>
          </div>
        </div>
        <div>
          <div className='grid grid-cols-[max-content_theme(width.20)_repeat(3,max-content)] gap-x-4 items-center'>
            <div className='col-span-5 font-semibold py-1'>Impact change by category</div>
            {data.impact.categories.map((category) => (
              <Fragment key={category.id}>
                <div className='text-zinc-800 py-1'>{category.name}</div>
                <div className='flex h-full'>
                  <div className='flex-1 py-1 flex justify-end'>
                    <div
                      className='relative left-px bg-emerald-50 border border-emerald-700 transition-[width,opacity]'
                      style={(() => {
                        const minMinusDelta = min(
                          data.impact.categories.map(({ impactDeltaPhysical: { raw } }) => raw).filter((value) => value < 0),
                        );
                        return minMinusDelta === undefined || category.impactDeltaPhysical.raw >= 0
                          ? { opacity: 0, width: '0%' }
                          : { width: `${(category.impactDeltaPhysical.raw / minMinusDelta) * 100}%` };
                      })()}
                    ></div>
                  </div>
                  <div className='w-px bg-zinc-500 z-[1]'></div>
                  <div className='flex-1 py-1 flex'>
                    <div
                      className='relative right-px bg-red-50 border border-red-500 transition-[width,opacity]'
                      style={(() => {
                        const maxPlusDelta = max(
                          data.impact.categories.map(({ impactDeltaPhysical: { raw } }) => raw).filter((value) => value > 0),
                        );
                        return maxPlusDelta === undefined || category.impactDeltaPhysical.raw <= 0
                          ? { opacity: 0, width: '0%' }
                          : { width: `${(category.impactDeltaPhysical.raw / maxPlusDelta) * 100}%` };
                      })()}
                    ></div>
                  </div>
                </div>
                <div className={category.impactDeltaPhysical.type === ImpactDeltaType.Higher ? 'text-red-500' : 'text-zinc-900'}>
                  {category.impactDelta.display}
                </div>
                <div
                  className={cn(
                    'flex justify-end gap-1 min-w-[50px]',
                    category.impactDeltaPhysical.type === ImpactDeltaType.Higher ? 'text-red-500' : 'text-zinc-900',
                  )}
                >
                  {formatPhysicalImpact(category.impactDeltaPhysical.raw)}
                </div>
                <div className='text-[10px]'>{category.unit}</div>
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    ) : (
      <div className='h-[494px] loading-skeleton'></div>
    );
  };

  return (
    <Menu
      ref={modalRef}
      zIndex={20}
      items={[
        {
          label: 'View impact change per kg/L of product',
          modal: (button, onOpenChange) => (
            <Modal
              onOpenChange={async (open: boolean) => {
                if (open) {
                  await fetchDataWithSelectedUnit('kg');
                } else {
                  setData(null);
                }
                onOpenChange(open);
              }}
              title={
                <div className='flex justify-between items-center'>
                  <div className='flex gap-x-4 items-center'>
                    <div className='size-8 flex items-center justify-center bg-indigo-50 rounded-lg'>
                      <FontAwesomeIcon icon={light('line-chart')} />
                    </div>
                    <div>
                      Impact change per kilogram/litre of product ({data?.scope.scaledProductAmount.value}{' '}
                      {data?.scope.scaledProductAmount.unit.name})
                    </div>
                  </div>
                  <button
                    onClick={modalRef.current!.close}
                    className='size-8 flex items-center justify-center bg-slate-100 rounded-lg'
                    type='button'
                  >
                    <FontAwesomeIcon icon={regular('times')} />
                  </button>
                </div>
              }
              body={body()}
            >
              {button}
            </Modal>
          ),
        },
        {
          label: 'View impact change per serving',
          disabled: !props.modellingPayload.product.servings,
          modal: (button, onOpenChange) => (
            <Modal
              onOpenChange={async (open: boolean) => {
                if (open) {
                  await fetchDataWithSelectedUnit('serving');
                } else {
                  setData(null);
                }
                onOpenChange(open);
              }}
              title={
                <div className='flex justify-between items-center'>
                  <div className='flex gap-x-4 items-center'>
                    <div className='size-8 flex items-center justify-center bg-indigo-50 rounded-lg'>
                      <FontAwesomeIcon icon={light('line-chart')} />
                    </div>
                    Impact change per serving of product ({data?.scope.scaledProductAmount.value}{' '}
                    {data?.scope.scaledProductAmount.unit.name})
                  </div>
                  <button
                    onClick={() => {
                      setData(null);
                      modalRef.current!.close();
                    }}
                    className='size-8 flex items-center justify-center bg-slate-100 rounded-lg'
                    type='button'
                  >
                    <FontAwesomeIcon icon={regular('times')} />
                  </button>
                </div>
              }
              body={body()}
            >
              {button}
            </Modal>
          ),
        },
      ]}
      placement='bottom-start'
    >
      {() => (
        <button type='button' className='flex items-center justify-center size-8 rounded-lg bg-indigo-200'>
          <FontAwesomeIcon className='size-6' icon={light('scale-balanced')} />
        </button>
      )}
    </Menu>
  );
};

interface ModalProps {
  title: ReactNode;
  open?: boolean;
  onClose?: () => void;
  onOpenChange?: (open: boolean) => void;
  preventDismiss?: boolean;
  children: ReactNode;
  body: ReactNode;
}

export const Modal = forwardRef((props: ModalProps, ref: ForwardedRef<ModalApi>) => {
  const [open, setOpen] = useState(props.open ?? false);

  useImperativeHandle(ref, () => ({
    open: () => {
      setOpenAndNotify(true);
    },
    close: () => {
      setOpenAndNotify(false);
    },
  }));

  const setOpenAndNotify = (newOpen: boolean) => {
    setOpen(newOpen);

    if (!newOpen && props.onClose) {
      props.onClose();
    }

    if (props.onOpenChange) {
      props.onOpenChange(newOpen);
    }
  };

  const { reference, floating, context } = useFloating({
    open,
    onOpenChange: setOpenAndNotify,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useFocusTrap(context),
    useRole(context, { role: 'dialog' }),
    useDismiss(context, { outsidePointerDown: !props.preventDismiss }),
  ]);

  return (
    <>
      {isValidElement(props.children) && cloneElement(props.children, getReferenceProps({ ref: reference }))}
      <FloatingPortal>
        {open && <Content {...props} floating={floating} getFloatingProps={getFloatingProps} setOpen={context.onOpenChange} />}
      </FloatingPortal>
    </>
  );
});

interface ModalContentProps {
  title: ReactNode;
  body: ReactNode;
  setOpen: (open: boolean) => void;
  floating: (node: HTMLElement | null) => void;
  getFloatingProps: (props?: HTMLProps<HTMLElement>) => any;
}

const Content = (props: ModalContentProps) => {
  const headerRef = useRef<HTMLDivElement>(null);

  return (
    <FloatingOverlay lockScroll className='flex justify-center items-center bg-neutral-400/75 z-50'>
      <div
        {...props.getFloatingProps({
          ref: props.floating,
        })}
        className='antialiased text-sm text-body m-8 bg-white shadow-2xl border rounded-xl w-full max-w-2xl'
      >
        <div className='flex flex-col'>
          <div ref={headerRef} className='flex flex-col font-semibold text-xl text-neutral-900 p-6'>
            {props.title}
          </div>
          <div
            style={
              {
                '--header-height': `${headerRef.current ? headerRef.current.getBoundingClientRect().height : 0}px`,
              } as CSSProperties
            }
            className={cn(
              'overflow-y-auto m-6 mt-0',
              'max-h-[calc(100vh_-_theme(spacing.8)*2_-_theme(spacing.6)*2_-_var(--header-height))]',
            )}
          >
            {props.body}
          </div>
        </div>
      </div>
    </FloatingOverlay>
  );
};
