import { duotone, light, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useFormikContext } from 'formik';
import { uniqBy } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import max from 'lodash/max';
import min from 'lodash/min';
import orderBy from 'lodash/orderBy';
import range from 'lodash/range';
import sortBy from 'lodash/sortBy';
import { Fragment, ReactNode, RefObject, createRef, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  ConsumptionNode,
  FacilityNode,
  FinalDestinationNode,
  GenericMovableFacilityNode,
  GenericNode,
  GenericRawMaterialNode,
  ImpactDelta,
  ImpactDeltaType,
  IngredientType,
  LifeCycleStage,
  ModellingBadgeType,
  ModellingChangeAction,
  ModellingPayload,
  NodeType,
  Position,
  PrimaryNode,
  ProductModelV3,
  ProductType,
  ProductionNode,
  ProductionWarehouseNode,
  RawMaterialNode,
  RawMaterialSupplierNode,
  StorageNode,
  StoreNode,
  TransportNode,
  WarehouseNode,
} from '../../../../api';
import { Menu, MenuItemConfig } from '../../../../components/Menu';
import { TooltipV3 } from '../../../../components/TooltipV3';
import { simplify, roundToLong } from '../../shared';
import { useLists } from '../../../../hooks/useLists';
import { Badge, Props as BadgeProps } from './Badge';
import { ConsumptionLocationDetails } from './ConsumptionLocationDetails';
import { ConsumptionLocationGraph } from './ConsumptionLocationGraph';
import { DisposalDetails } from './DisposalDetails';
import { FinalDestinationDetails } from './FinalDestinationDetails';
import { GridSize } from './GridSize';
import { ImpactDeltaBadge } from './ImpactDeltaBadge';
import { IngredientDetails } from './IngredientDetails';
import { PackagingDetails } from './PackagingDetails';
import { ProductionFacilityDetails } from './ProductionFacilityDetails';
import { ProductionFacilityGraph } from './ProductionFacilityGraph';
import { ProductionWarehouseDetails } from './ProductionWarehouseDetails';
import { RenameModal } from './RenameModal';
import { StorageItems } from './StorageItems';
import { StoreDetails } from './StoreDetails';
import { TitleBadges, Props as TitleBadgesProps } from './TitleBadges';
import { TransportDetails } from './TransportDetails';
import { TransportLink } from './TransportLink';
import { ValidationErrors } from './ValidationErrors';
import { WarehouseDetails } from './WarehouseDetails';
import {
  UpdateSideEffects,
  formatPhysicalImpact,
  getConnectedTransports,
  getConsumptionLocationsFromAll,
  getConsumptionLocationsWithDeleted,
  getDisposalsWithDeleted,
  getDistributionMovableNodesWithDeleted,
  getFacilityNodesFromAll,
  getFinalDestinationsWithDeleted,
  getIngredientSuppliersWithDeleted,
  getIngredientsWithDeleted,
  getMovableNodesFromAll,
  getPackagingSuppliersWithDeleted,
  getPackagingsWithDeleted,
  getPrimaryAndMaterialSuppliersFromAll,
  getPrimaryAndMaterialSuppliersWithDeleted,
  getProductionFacilitiesWithDeleted,
  getProductionMovableNodesWithDeleted,
  getProductionWarehousesWithDeleted,
  getRawMaterials,
  getRawMaterialsFromAll,
  getStepsFromAll,
  getStoresWithDeleted,
  getTransportsWithDeleted,
  getValidationMessages,
  getWarehousesWithDeleted,
  graphCleanup,
  hasValidationMessage,
  newNodeId,
  sizeToPositions,
} from './dataModel';
import { useAppRoutes } from '../../../../hooks/useAppRoutes';
import { ScaledToScopeImpacts } from './ScaledToScopeImpacts';

enum InnerView {
  ProductionFacility,
  Storage,
  ConsumptionLocation,
}

interface DragState {
  mouseDown: { x: number; y: number };
  nodeId: string;
  inProgress: boolean;
}

interface Props extends TitleBadgesProps {
  payload: ModellingPayload;
  showOriginal: {
    value: boolean;
    toggle: () => void;
  };
  canUndo: boolean;
  canRedo: boolean;
  onUndo: () => void;
  onRedo: () => void;
}

export const Graph = (props: Props) => {
  const navigate = useNavigate();
  const { routes } = useAppRoutes();
  const formik = useFormikContext<ProductModelV3>();
  const lists = useLists();
  const { payload, waiting, showOriginal } = props;
  const savedScroll = useRef({ left: 0, top: 0 });
  const [hoveredInnerViewButtonNodeId, setHoveredInnerViewButtonNodeId] = useState('');
  const [hoveredMenuButtonNodeId, setHoveredMenuButtonNodeId] = useState('');
  const [dragState, setDragState] = useState<DragState | null>(null);

  const [innerView, setInnerView] = useState<{ name: InnerView; nodeId: string } | undefined>();
  const [showChanges, setShowChanges] = useState(true);
  const [majorContributorsPopover, setMajorContributorsPopover] = useState(false);

  const [impactsExpanded, setImpactsExpanded] = useState(false);
  const [impactsSelectedCategoryId, setImpactsSelectedCategoryId] = useState(props.payload.impact.categories[0].id);
  const impactsSelectedCategory = props.payload.impact.categories.find(({ id }) => id === impactsSelectedCategoryId)!;
  const [forecastExpanded, setForecastExpanded] = useState(false);
  const [forecastSelectedCategoryId, setForecastSelectedCategoryId] = useState(props.payload.impact.categories[0].id);
  const forecastSelectedCategory = props.payload.impact.categories.find(({ id }) => id === forecastSelectedCategoryId)!;
  const [forecastUnits, setForecastUnits] = useState<number | ''>('');

  const final = payload.product.productType === ProductType.Final;
  const intermediate = payload.product.productType === ProductType.Intermediate;
  const internal = payload.product.productType === ProductType.Internal;
  const readOnlyMode = showOriginal.value || !showChanges;

  const ignoreLessImportantValidation = false;
  const showingValidation = getValidationMessages(formik, { ignoreLessImportant: false }).length > 0;

  const addPackagingRef = useRef<HTMLDivElement>(null);
  const addWarehouseRef = useRef<HTMLDivElement>(null);
  const addStoreRef = useRef<HTMLDivElement>(null);
  const nodeRefs = useRef<Record<string, RefObject<HTMLDivElement>>>({});
  const lifeCycleBoxRefs = useRef<Record<LifeCycleStage, RefObject<HTMLDivElement>>>({
    [LifeCycleStage.RawMaterials]: createRef(),
    [LifeCycleStage.Production]: createRef(),
    ...(final || intermediate
      ? {
          [LifeCycleStage.Distribution]: createRef(),
        }
      : ({} as any)),
    ...(final
      ? {
          [LifeCycleStage.Use]: createRef(),
          [LifeCycleStage.EndOfLife]: createRef(),
        }
      : ({} as any)),
  });
  const scrollableRef = useRef<HTMLDivElement>(null);
  const productionSlotsRefs = useRef<Record<string, RefObject<HTMLDivElement>>>({});
  const distributionSlotsRefs = useRef<Record<string, RefObject<HTMLDivElement>>>({});

  useLayoutEffect(() => {
    if (innerView) {
      scrollableRef.current!.scrollTo(0, 0);
    } else {
      scrollableRef.current!.scrollTo(savedScroll.current);
    }
  }, [innerView]);

  useEffect(() => {
    const onMouseUp = () => {
      setDragState(null);
    };

    window.addEventListener('mouseup', onMouseUp);

    return () => window.removeEventListener('mouseup', onMouseUp);
  }, [dragState]);

  useEffect(() => {
    if (dragState) {
      const onMouseMove = (event: MouseEvent) => {
        if (!waiting && dragState.inProgress) {
          const hoveredSlot = [
            ...Object.keys(productionSlotsRefs.current).map((position) => ({
              position,
              ref: productionSlotsRefs.current[position].current,
            })),
            ...Object.keys(distributionSlotsRefs.current).map((position) => ({
              position,
              ref: distributionSlotsRefs.current[position].current,
            })),
          ]
            .filter(({ ref }) => ref)
            .find(({ ref }) => {
              const rect = ref!.getBoundingClientRect();
              return event.clientX >= rect.left && event.clientX <= rect.right && event.clientY >= rect.top && event.clientY <= rect.bottom;
            });

          if (hoveredSlot) {
            formik.setValues((oldValues) => {
              const newValues = cloneDeep(oldValues);
              getMovableNodesFromAll(newValues.nodes).find((node) => node.id === dragState.nodeId)!.layoutPosition = {
                x: parseInt(hoveredSlot.position.split('.')[0]),
                y: parseInt(hoveredSlot.position.split('.')[1]),
              };
              return newValues;
            });
          }
        } else if (Math.max(Math.abs(event.clientX - dragState.mouseDown.x), Math.abs(event.clientY - dragState.mouseDown.y)) > 5) {
          setDragState((oldState) => (oldState ? { ...oldState, inProgress: true } : oldState));
        }
      };

      window.addEventListener('mousemove', onMouseMove);

      return () => window.removeEventListener('mousemove', onMouseMove);
    }
  }, [dragState, formik, waiting]);

  getPrimaryAndMaterialSuppliersWithDeleted(formik, payload, readOnlyMode).forEach((node) => {
    nodeRefs.current[node.id] = nodeRefs.current[node.id] ?? createRef();
  });

  sizeToPositions(formik.values.layoutGrids.production).forEach((position) => {
    productionSlotsRefs.current[`${position.x}.${position.y}`] = productionSlotsRefs.current[`${position.x}.${position.y}`] ?? createRef();
  });

  sizeToPositions(formik.values.layoutGrids.distribution).forEach((position) => {
    distributionSlotsRefs.current[`${position.x}.${position.y}`] =
      distributionSlotsRefs.current[`${position.x}.${position.y}`] ?? createRef();
  });

  const sortAndFilterNodes = <T extends GenericNode>(nodes: T[]) =>
    orderBy(
      nodes.filter(({ displayName }) => displayName),
      [
        (node) =>
          getRawMaterials(formik).some(({ id }) => id === node.id)
            ? hasValidationMessage(node, formik, { ignoreLessImportant: ignoreLessImportantValidation })
            : '',
        (node) => (getRawMaterials(formik).some(({ id }) => id === node.id) ? (node as any as GenericRawMaterialNode).amount.value : ''),
        ({ displayName }) => displayName,
      ],
      ['desc', 'desc', 'asc'],
    );

  const addReplaceTransport = (node: TransportNode, fromId: string) => {
    formik.setValues((values) => {
      const newValues = cloneDeep(values);

      getPrimaryAndMaterialSuppliersFromAll(newValues.nodes).forEach((n) => {
        n.edges = n.edges.filter((edgeId) => edgeId !== node.id);
      });
      getPrimaryAndMaterialSuppliersFromAll(newValues.nodes)
        .find(({ id }) => id === fromId)!
        .edges.push(node.id);

      newValues.nodes = [...newValues.nodes.filter(({ id }) => id !== node.id), node];
      graphCleanup(newValues, payload, lists);

      return newValues;
    });
  };

  const assignLayoutPositions = (values: ProductModelV3, nodes: PrimaryNode[]) => {
    (nodes as GenericMovableFacilityNode[]).forEach((node) => {
      [
        { gridNodes: getProductionMovableNodesWithDeleted(values.nodes, payload, readOnlyMode), grid: values.layoutGrids.production },
        {
          gridNodes: getDistributionMovableNodesWithDeleted(values.nodes, payload, readOnlyMode),
          grid: values.layoutGrids.distribution,
        },
      ].forEach(({ gridNodes, grid }) => {
        if (gridNodes.some(({ id }) => id === node.id)) {
          let firstEmptyRowIndex = range(0, grid.height).find((y) => !gridNodes.some(({ layoutPosition }) => layoutPosition?.y === y));

          if (firstEmptyRowIndex === undefined) {
            firstEmptyRowIndex = grid.height++;
          }

          node.layoutPosition = { x: 0, y: firstEmptyRowIndex };
        }
      });
    });
  };

  const add = (node: PrimaryNode) => {
    formik.setValues((values) => {
      const newValues = cloneDeep(values);
      newValues.nodes = [...newValues.nodes, node];
      assignLayoutPositions(newValues, [node]);
      return newValues;
    });
  };

  const addAll = (nodes: PrimaryNode[]) => {
    formik.setValues((values) => {
      const newValues = cloneDeep(values);
      newValues.nodes = [...newValues.nodes, ...nodes];
      assignLayoutPositions(newValues, nodes);
      return newValues;
    });
  };

  const duplicate = (node: RawMaterialNode) => {
    formik.setValues((values) => {
      const newValues = cloneDeep(values);
      const newNode = cloneDeep(node);
      newNode.id = newNodeId();
      delete newNode.index;
      newNode.nodes.forEach((supplier) => {
        supplier.id = newNodeId();
        supplier.edges = [];
      });
      newValues.nodes = [...newValues.nodes, newNode];
      return newValues;
    });
  };

  const replace = (node: PrimaryNode, sideEffects?: UpdateSideEffects) => {
    formik.setValues((values) => {
      const newValues = cloneDeep(values);
      const oldNode = newValues.nodes.find(({ id }) => id === node.id);

      newValues.nodes = [...newValues.nodes.filter(({ id }) => id !== node.id), node];

      if (
        getFacilityNodesFromAll(newValues.nodes).some(({ id }) => id === node.id) &&
        (oldNode as FacilityNode).facility.id !== (node as FacilityNode).facility.id
      ) {
        getConnectedTransports(node, newValues.nodes).forEach((transport) => {
          transport.legs = [];
        });
      }

      if (getRawMaterialsFromAll(newValues.nodes).some(({ id }) => id === node.id)) {
        (node as GenericRawMaterialNode).nodes
          .filter((supplier) => {
            const oldSupplier = (oldNode as GenericRawMaterialNode).nodes.find(({ id }) => id === supplier.id);
            return oldSupplier && oldSupplier.location.id !== supplier.location.id;
          })
          .forEach((supplier) => {
            getConnectedTransports(supplier, newValues.nodes).forEach((transport) => {
              transport.legs = [];
            });
          });
      }

      sideEffects?.stepInputAmounts.forEach(({ stepId, inputId, value }) => {
        getStepsFromAll(newValues.nodes)
          .find(({ id }) => id === stepId)!
          .inputs.find(({ id }) => id === inputId)!.amountValue = value;
      });

      graphCleanup(newValues, payload, lists);

      return newValues;
    });
  };

  const remove = (node: PrimaryNode) => {
    formik.setValues((values) => {
      const newValues = cloneDeep(values);
      newValues.nodes = newValues.nodes.filter(({ id }) => id !== node.id);
      graphCleanup(newValues, payload, lists);
      return newValues;
    });
  };

  const getChange = (node?: GenericNode) => payload.changes.find(({ id }) => id === node?.id);

  const getBadge = (node?: GenericNode) => payload.badges.find(({ id }) => id === node?.id);

  const isAdded = (node?: GenericNode) =>
    payload.changes.some(({ id, action }) => id === node?.id && action === ModellingChangeAction.Added);

  const isUpdated = (node?: GenericNode) =>
    payload.changes.some(({ id, action }) => id === node?.id && action === ModellingChangeAction.Updated);

  const isDeleted = (node?: GenericNode) =>
    !readOnlyMode && payload.changes.some(({ id, action }) => id === node?.id && action === ModellingChangeAction.Deleted);

  const getBorderStyle = (node?: GenericNode) => {
    return `border ${(() => {
      if (!readOnlyMode) {
        if (node && hasValidationMessage(node, formik, { ignoreLessImportant: ignoreLessImportantValidation })) {
          return 'border-red-500';
        }

        if (isAdded(node)) {
          return 'border-blue-500';
        }

        if (isUpdated(node)) {
          return 'border-[#9C75FA]';
        }

        if (isDeleted(node)) {
          return 'border-zinc-400 border-dashed';
        }
      }

      if (node) {
        return 'border-transparent shadow-[0_0_6px_0_rgba(0,0,0,0.1)]';
      }

      return 'border-transparent';
    })()}`;
  };

  const getSupplierBorderStyle = (node: RawMaterialSupplierNode) => {
    return `border ${(() => {
      if (!readOnlyMode) {
        if (hasValidationMessage(node, formik, { ignoreLessImportant: ignoreLessImportantValidation })) {
          return 'border-red-500';
        }

        if (isDeleted(node)) {
          return 'border-zinc-400 border-dashed';
        }
      }

      return 'border-transparent shadow-[0_0_2px_0_rgba(0,0,0,0.2)]';
    })()}`;
  };

  const renderNodeBadges = (node?: GenericNode, options?: { noDelta: boolean }) => {
    const change = getChange(node);
    const hasBadge = !!getBadge(node);

    if (!readOnlyMode && (change || hasBadge)) {
      return renderBadges({
        added: isAdded(node),
        updated: isUpdated(node),
        deleted: isDeleted(node),
        highImpact: !change && hasBadge,
        highImpactIcon: hasBadge,
        impactDelta: change && !options?.noDelta ? change.impactDelta : undefined,
      });
    }

    return <></>;
  };

  const renderInnerViewBadges = (node?: ProductionNode | StorageNode) => {
    if (node) {
      const hasChanges =
        node.type === NodeType.Production
          ? node.steps.some((step) => getChange(step))
          : Object.keys(getChange(node)?.subChanges ?? {}).length > 0;
      const hasBadge = (node.type === NodeType.Production ? node.steps : [node]).some((step) => getBadge(step));

      if (!readOnlyMode && (hasChanges || hasBadge)) {
        return renderBadges({
          updated: hasChanges,
          highImpact: !hasChanges && hasBadge,
          highImpactIcon: hasBadge,
        });
      }
    }

    return <></>;
  };

  const renderBadges = (props: BadgeProps & { highImpactIcon?: boolean; impactDelta?: ImpactDelta }) => (
    <div className='w-full flex justify-between leading-none'>
      <div className='flex gap-1 text-[8px] font-semibold tracking-wider'>
        <Badge {...props} />
        {props.impactDelta && <ImpactDeltaBadge data={props.impactDelta} className='px-1.5 py-1 rounded' />}
      </div>
      {props.highImpactIcon && <FontAwesomeIcon className='text-amber-400 h-4 aspect-square' icon={solid('seal-exclamation')} />}
    </div>
  );

  return (
    <div className='flex flex-col'>
      <div className='divide-y divide-zinc-300 -mx-6 xl:-mx-[calc((100vw-theme(screens.xl))/2+theme(spacing.6))]'>
        <div className='flex justify-center pb-4'>
          <div className='flex justify-between gap-12 px-12 w-[theme(screens.xl)]'>
            <div className='flex gap-4 truncate'>
              {(innerView || !showingValidation) && (
                <button
                  type='button'
                  className='shrink-0 flex justify-center items-center w-8 aspect-square rounded-md text-lg text-brand bg-[#E8EAF5] active:scale-90'
                  onClick={() => {
                    if (innerView) {
                      setInnerView(undefined);
                    } else {
                      navigate(routes.products.list);
                    }
                  }}
                >
                  <FontAwesomeIcon icon={solid('chevron-left')} />
                </button>
              )}
              <div className='flex gap-4 items-center truncate'>
                <div className='flex gap-2 items-center text-base truncate'>
                  <NavLink to={routes.products.list} className='text-zinc-500'>
                    Products
                  </NavLink>
                  <FontAwesomeIcon size='xs' icon={solid('chevron-right')} className='text-zinc-500' />
                  <RenameModal
                    onSave={(title) => {
                      formik.setValues((values) => {
                        const newValues = cloneDeep(values);
                        newValues.title = title;
                        return newValues;
                      });
                    }}
                  >
                    <button type='button' className='truncate flex items-center gap-2'>
                      <div className='truncate font-semibold'>{formik.values.title}</div>
                      <FontAwesomeIcon icon={regular('pen-to-square')} />
                    </button>
                  </RenameModal>
                  {innerView && (
                    <>
                      <FontAwesomeIcon size='xs' icon={solid('chevron-right')} />
                      <div className='font-semibold'>{formik.values.nodes.find(({ id }) => id === innerView.nodeId)!.displayName}</div>
                    </>
                  )}
                </div>
                <TitleBadges {...props} />
              </div>
            </div>
            <div className='shrink-0 flex gap-4'>
              <div className='flex gap-2'>
                <button
                  type='button'
                  disabled={readOnlyMode || !props.canUndo}
                  className='flex justify-center items-center w-8 aspect-square rounded-lg bg-slate-100 text-[#220066] [&:active:not(:disabled)]:scale-95'
                  onClick={props.onUndo}
                >
                  <FontAwesomeIcon icon={light('rotate-left')} />
                </button>
                <button
                  type='button'
                  disabled={readOnlyMode || !props.canRedo}
                  className='flex justify-center items-center w-8 aspect-square rounded-lg bg-slate-100 text-[#220066] [&:active:not(:disabled)]:scale-95'
                  onClick={props.onRedo}
                >
                  <FontAwesomeIcon icon={light('rotate-right')} />
                </button>
                <button
                  type='button'
                  disabled={waiting}
                  className='flex items-center px-3 h-8 rounded-lg bg-slate-100 text-zinc-600 [&:active:not(:disabled)]:scale-95'
                  onClick={showOriginal.toggle}
                >
                  See {showOriginal.value ? 'modelled' : 'original'}
                </button>
                <ScaledToScopeImpacts modellingPayload={props.payload} />
                <button
                  type='button'
                  disabled={waiting || showOriginal.value}
                  className={cn('flex items-center gap-2 px-2 h-8 rounded-lg bg-slate-100 text-zinc-600', {
                    'disabled:opacity-70': showOriginal.value,
                  })}
                  onClick={() => setShowChanges((current) => !current)}
                >
                  <div>Changes</div>
                  <div className={cn('flex w-7 h-4 rounded-full p-0.5', showChanges ? 'justify-end bg-brand' : 'bg-gray-300')}>
                    <div className='bg-white rounded-full h-full aspect-square' />
                  </div>
                </button>
                {props.payload.badges.some(({ type }) => type === ModellingBadgeType.HighImpact) && (
                  <TooltipV3
                    parentControl={{
                      open: majorContributorsPopover,
                      onOpenChange: setMajorContributorsPopover,
                    }}
                    offsetMain={6}
                    offsetCross={8}
                    placement='bottom-end'
                    content={
                      <div className='flex flex-col gap-1 rounded-lg text-zinc-800 bg-amber-50 px-4 py-2.5 shadow-[0_0_6px_2px_rgba(0,0,0,0.1)]'>
                        <div className='uppercase text-zinc-500 text-[10px] tracking-wider'>impact major contributors</div>
                        <div className='flex flex-col gap-2'>
                          {props.payload.majorContributors.map((item) => (
                            <div key={item.id} className='flex items-center gap-1'>
                              {item.parents.map((parent) => (
                                <Fragment key={parent}>
                                  <div>{parent}</div>
                                  <FontAwesomeIcon size='xs' icon={regular('chevron-right')} />
                                </Fragment>
                              ))}
                              <div className='font-semibold'>{item.name}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    }
                  >
                    <div>
                      <TooltipV3
                        disabled={majorContributorsPopover}
                        placement='left'
                        content={
                          <div className='rounded-lg text-zinc-800 border border-amber-400 bg-amber-50 px-4 py-2 w-[395px]'>
                            Items highlighted with this symbol contribute the most to your impact. Click on the icon to check them on a
                            list.
                          </div>
                        }
                      >
                        <button
                          type='button'
                          className='flex justify-center items-center w-8 aspect-square rounded-lg text-white bg-amber-400 active:scale-95'
                          onClick={() => setMajorContributorsPopover((toggle) => !toggle)}
                        >
                          <FontAwesomeIcon className='h-5 aspect-square' icon={light('seal-exclamation')} />
                        </button>
                      </TooltipV3>
                    </div>
                  </TooltipV3>
                )}
              </div>
              <div className='flex gap-2'>
                {!innerView && (
                  <button
                    type='button'
                    className={cn(
                      'bg-brand rounded-full px-4 py-1 text-white font-semibold',
                      '[&:active:not(:disabled)]:scale-95 disabled:bg-neutral-300',
                    )}
                    disabled={showingValidation}
                    onClick={() => navigate(routes.products.modelOverview(payload.product.id, payload.model.id))}
                  >
                    Generate report
                  </button>
                )}
                {showingValidation && (
                  <ValidationErrors selectedNodeId={innerView?.nodeId} ignoreLessImportant={ignoreLessImportantValidation} />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='flex justify-center bg-slate-50 py-4'>
          <div className='grid grid-cols-2 gap-4 px-12 w-[theme(screens.xl)] h-16'>
            <div className='relative'>
              <div
                className={cn(
                  'w-full absolute z-10 flex flex-col gap-4 text-zinc-800 bg-white rounded-2xl p-4 border border-zinc-200 transition',
                  {
                    'shadow-[0_1px_10px_0_rgba(0,0,0,0.15)]': impactsExpanded,
                  },
                )}
              >
                <div className='flex justify-between items-center gap-1'>
                  <div className='flex items-center gap-2'>
                    {showingValidation && (
                      <TooltipV3
                        offsetMain={6}
                        offsetCross={-16}
                        placement='bottom-start'
                        content={
                          <div className='rounded-lg text-zinc-800 bg-red-50 px-4 py-2.5 shadow-[0_0_6px_2px_rgba(0,0,0,0.1)] w-[420px]'>
                            Impact values are inaccurate due to errors in the main graph. They will update once all errors are solved.
                          </div>
                        }
                      >
                        <div>
                          <FontAwesomeIcon size='sm' className='text-red-500' icon={regular('exclamation-triangle')} />
                        </div>
                      </TooltipV3>
                    )}
                    <div
                      className={cn('flex justify-center items-center w-8 aspect-square rounded-lg bg-slate-100 text-[#220066]', {
                        'opacity-50': showingValidation,
                      })}
                    >
                      <FontAwesomeIcon icon={light('line-chart')} />
                    </div>
                    <div
                      className={cn('font-semibold', {
                        'opacity-50': showingValidation,
                      })}
                    >
                      Impact change{impactsExpanded && ' per unit of product'}
                    </div>
                  </div>
                  {!impactsExpanded && (
                    <>
                      <div
                        className={cn('flex items-center gap-2', {
                          'opacity-50': showingValidation,
                        })}
                      >
                        <div
                          className={cn(
                            'pr-3.5 p-2 rounded-2xl text-base leading-none flex items-center gap-x-2',
                            {
                              [ImpactDeltaType.Higher]: 'text-red-500 bg-red-50',
                              [ImpactDeltaType.Lower]: 'text-emerald-700 bg-emerald-50',
                              [ImpactDeltaType.Zero]: 'text-zinc-600 bg-zinc-100',
                            }[payload.impact.impactDelta.type],
                          )}
                        >
                          <div
                            className={cn(
                              'flex justify-center items-center size-4 rounded-full text-white',
                              {
                                [ImpactDeltaType.Higher]: 'bg-red-500',
                                [ImpactDeltaType.Lower]: 'bg-emerald-700',
                                [ImpactDeltaType.Zero]: 'bg-zinc-400',
                              }[payload.impact.impactDelta.type],
                            )}
                          >
                            <FontAwesomeIcon
                              size='xs'
                              icon={
                                {
                                  [ImpactDeltaType.Higher]: solid('arrow-up'),
                                  [ImpactDeltaType.Lower]: solid('arrow-down'),
                                  [ImpactDeltaType.Zero]: solid('arrow-right'),
                                }[payload.impact.impactDelta.type]
                              }
                            />
                          </div>
                          {props.payload.impact.impactDelta.display} total
                        </div>
                      </div>
                      <div
                        className={cn('flex items-center gap-2', {
                          'opacity-50': showingValidation,
                        })}
                      >
                        <div className='relative flex items-center'>
                          <select
                            title={impactsSelectedCategory.name}
                            className='appearance-none rounded-full py-1.5 pl-4 pr-8 w-[calc(theme(width.1)*37.5)] bg-zinc-50 text-zinc-600'
                            value={impactsSelectedCategory.id}
                            onChange={(event) => setImpactsSelectedCategoryId(event.target.value)}
                          >
                            {props.payload.impact.categories.map((category) => (
                              <option key={category.id} value={category.id}>
                                {category.name}
                              </option>
                            ))}
                          </select>
                          <FontAwesomeIcon className='absolute right-3 text-brand pointer-events-none' icon={light('chevron-down')} />
                        </div>
                        <div
                          className={cn(
                            'px-3.5 py-2 rounded-2xl text-base leading-none',
                            {
                              [ImpactDeltaType.Higher]: 'text-red-500 bg-red-50',
                              [ImpactDeltaType.Lower]: 'text-emerald-700 bg-emerald-50',
                              [ImpactDeltaType.Zero]: 'text-zinc-600 bg-zinc-100',
                            }[impactsSelectedCategory.impactDelta.type],
                          )}
                        >
                          {impactsSelectedCategory.impactDelta.display}
                        </div>
                      </div>
                    </>
                  )}
                  <button
                    type='button'
                    className='flex justify-center items-center w-8 aspect-square rounded-lg bg-slate-100 text-[#220066] active:scale-95'
                    onClick={() => setImpactsExpanded((current) => !current)}
                  >
                    <FontAwesomeIcon className='text-lg' icon={impactsExpanded ? regular('chevron-up') : regular('chevron-down')} />
                  </button>
                </div>
                {impactsExpanded && (
                  <>
                    <div className='flex flex-col gap-4'>
                      <div className='flex gap-3'>
                        <div className='flex-1 flex flex-col gap-2 px-4 py-3 bg-slate-100 border border-indigo-100 rounded-2xl'>
                          <div className='font-semibold'>Original impact</div>
                          <div title={roundToLong(props.payload.impact.originalImpactPoints)} className='inline-flex items-center gap-1.5'>
                            <span className='text-zinc-900 text-base'>{simplify(props.payload.impact.originalImpactPoints)}</span>
                            <span className='uppercase text-zinc-500 tracking-widest text-[10px]'>Impact points</span>
                          </div>
                        </div>
                        <div className='flex-1 flex flex-col gap-2 px-4 py-3 border border-zinc-200 rounded-2xl'>
                          <div className='font-semibold'>Modelled impact</div>
                          <div title={roundToLong(props.payload.impact.modelImpactPoints)} className='inline-flex items-center gap-1.5'>
                            <span className='text-zinc-900 text-base'>{simplify(props.payload.impact.modelImpactPoints)}</span>
                            <span className='uppercase text-zinc-500 tracking-widest text-[10px]'>Impact points</span>
                          </div>
                        </div>
                        <div
                          className={cn(
                            'flex justify-center items-center rounded-2xl text-base px-4',
                            {
                              [ImpactDeltaType.Higher]: 'text-red-500 bg-red-50',
                              [ImpactDeltaType.Lower]: 'text-emerald-700 bg-emerald-50',
                              [ImpactDeltaType.Zero]: 'text-zinc-600 bg-zinc-100',
                            }[props.payload.impact.impactDelta.type],
                          )}
                        >
                          {props.payload.impact.impactDelta.display}
                        </div>
                      </div>
                      <div className='flex flex-col gap-3 px-1 mt-2 text-xs'>
                        <div className='font-semibold'>Impact change by category</div>
                        <div className='grid grid-cols-[max-content_theme(width.20)_max-content] gap-x-4 items-center'>
                          {props.payload.impact.categories.map((category) => (
                            <Fragment key={category.id}>
                              <div className='text-zinc-800 py-1'>{category.name}</div>
                              <div className='flex h-full'>
                                <div className='flex-1 py-1 flex justify-end'>
                                  <div
                                    className='relative left-px bg-emerald-50 border border-emerald-700 transition-[width,opacity]'
                                    style={(() => {
                                      const minMinusDelta = min(
                                        props.payload.impact.categories.map(({ impactDelta: { raw } }) => raw).filter((value) => value < 0),
                                      );
                                      return minMinusDelta === undefined || category.impactDelta.raw >= 0
                                        ? { opacity: 0, width: '0%' }
                                        : { width: `${(category.impactDelta.raw / minMinusDelta) * 100}%` };
                                    })()}
                                  ></div>
                                </div>
                                <div className='w-px bg-zinc-500 z-[1]'></div>
                                <div className='flex-1 py-1 flex'>
                                  <div
                                    className='relative right-px bg-red-50 border border-red-500 transition-[width,opacity]'
                                    style={(() => {
                                      const maxPlusDelta = max(
                                        props.payload.impact.categories.map(({ impactDelta: { raw } }) => raw).filter((value) => value > 0),
                                      );
                                      return maxPlusDelta === undefined || category.impactDelta.raw <= 0
                                        ? { opacity: 0, width: '0%' }
                                        : { width: `${(category.impactDelta.raw / maxPlusDelta) * 100}%` };
                                    })()}
                                  ></div>
                                </div>
                              </div>
                              <div
                                className={cn(
                                  'flex justify-end min-w-[50px]',
                                  category.impactDelta.type === ImpactDeltaType.Higher ? 'text-red-500' : 'text-zinc-900',
                                )}
                              >
                                {category.impactDelta.display}
                              </div>
                            </Fragment>
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className='relative'>
              <div
                className={cn(
                  'w-full absolute z-10 flex flex-col gap-4 text-zinc-800 bg-white rounded-2xl p-4 border border-zinc-200 transition',
                  {
                    'shadow-[0_1px_10px_0_rgba(0,0,0,0.15)]': forecastExpanded,
                  },
                )}
              >
                <div className='flex justify-between items-center gap-1'>
                  <div className='shrink-0 flex items-center gap-2'>
                    {showingValidation && (
                      <TooltipV3
                        offsetMain={6}
                        offsetCross={-16}
                        placement='bottom-start'
                        content={
                          <div className='rounded-lg text-zinc-800 bg-red-50 px-4 py-2.5 shadow-[0_0_6px_2px_rgba(0,0,0,0.1)] w-[420px]'>
                            Impact values are inaccurate due to errors in the main graph. They will update once all errors are solved.
                          </div>
                        }
                      >
                        <div>
                          <FontAwesomeIcon size='sm' className='text-red-500' icon={regular('exclamation-triangle')} />
                        </div>
                      </TooltipV3>
                    )}
                    <div
                      className={cn('flex justify-center items-center w-8 aspect-square rounded-lg bg-slate-100 text-[#220066]', {
                        'opacity-50': showingValidation,
                      })}
                    >
                      <FontAwesomeIcon icon={light('boxes-stacked')} />
                    </div>
                    <div
                      className={cn('font-semibold', {
                        'opacity-50': showingValidation,
                      })}
                    >
                      Monthly forecast
                    </div>
                    <div
                      className={cn('relative flex items-center ml-2', {
                        'opacity-50': showingValidation,
                      })}
                    >
                      <FontAwesomeIcon className='absolute left-3 text-brand' icon={light('box-taped')} />
                      <input
                        type='number'
                        className='rounded-full pl-8 py-1.5 pr-3 w-24 bg-zinc-50 text-zinc-700'
                        placeholder='Units…'
                        value={forecastUnits}
                        onChange={(event) => setForecastUnits(isNaN(parseInt(event.target.value)) ? '' : parseInt(event.target.value))}
                      />
                    </div>
                    <div
                      className={cn('relative flex items-center', {
                        'opacity-50': showingValidation,
                      })}
                    >
                      <select
                        title={forecastSelectedCategory.name}
                        className='appearance-none rounded-full py-1.5 pl-4 pr-8 w-28 bg-zinc-50 text-zinc-600'
                        value={forecastSelectedCategory.id}
                        onChange={(event) => setForecastSelectedCategoryId(event.target.value)}
                      >
                        {props.payload.impact.categories.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                      <FontAwesomeIcon className='absolute right-3 text-brand pointer-events-none' icon={light('chevron-down')} />
                    </div>
                  </div>
                  <div className='flex items-center gap-2 min-w-0'>
                    {!forecastExpanded && (
                      <div
                        className={cn(
                          'flex items-center gap-1 px-3.5 py-2 rounded-2xl text-base leading-none truncate',
                          {
                            [ImpactDeltaType.Higher]: 'text-red-500 bg-red-50',
                            [ImpactDeltaType.Lower]: 'text-emerald-700 bg-emerald-50',
                            [ImpactDeltaType.Zero]: 'text-zinc-600 bg-zinc-100',
                          }[forecastUnits === '' ? ImpactDeltaType.Zero : forecastSelectedCategory.impactDeltaPhysical.type],
                          {
                            'opacity-50': showingValidation,
                          },
                        )}
                      >
                        <div>
                          {forecastUnits === ''
                            ? 0
                            : formatPhysicalImpact(forecastSelectedCategory.impactDeltaPhysical.raw * forecastUnits)}
                        </div>
                        <div className='text-[10px] truncate' title={forecastSelectedCategory.unit}>
                          {forecastSelectedCategory.unit}
                        </div>
                      </div>
                    )}
                    <button
                      type='button'
                      className='shrink-0 flex justify-center items-center w-8 aspect-square rounded-lg bg-slate-100 text-[#220066] active:scale-95'
                      onClick={() => setForecastExpanded((current) => !current)}
                    >
                      <FontAwesomeIcon className='text-lg' icon={forecastExpanded ? regular('chevron-up') : regular('chevron-down')} />
                    </button>
                  </div>
                </div>
                {forecastExpanded && (
                  <>
                    <div className='flex flex-col gap-4'>
                      {forecastUnits === '' && (
                        <div className='flex items-center gap-4 p-4 border border-zinc-200 rounded-lg'>
                          <div className='shrink-0 flex justify-center items-center text-zinc-700 bg-brandLime rounded-full size-10'>
                            <FontAwesomeIcon icon={light('lightbulb')} size='lg' />
                          </div>
                          <div>
                            <span className='font-semibold text-zinc-900'>Type in a number of units above.</span> Measure the potential
                            impact reduction at scale by adding the monthly forecasted production volume of this product.
                          </div>
                        </div>
                      )}
                      <div className='flex gap-3'>
                        <div className='flex-1 flex flex-col gap-2 px-4 py-3 bg-slate-100 border border-indigo-100 rounded-2xl'>
                          <div className='font-semibold'>Original impact</div>
                          {forecastUnits !== '' ? (
                            <div
                              title={roundToLong(props.payload.impact.originalImpactPoints * forecastUnits)}
                              className='inline-flex items-center gap-1.5'
                            >
                              <span className='text-zinc-900 text-base'>
                                {simplify(props.payload.impact.originalImpactPoints * forecastUnits)}
                              </span>
                              <span className='uppercase text-zinc-500 tracking-widest text-[10px]'>Impact points</span>
                            </div>
                          ) : (
                            <div className='uppercase text-zinc-800 tracking-widest text-[10px]'>No units entered</div>
                          )}
                        </div>
                        <div className='flex-1 flex flex-col gap-2 px-4 py-3 border border-zinc-200 rounded-2xl'>
                          <div className='font-semibold'>Modelled impact</div>
                          {forecastUnits !== '' ? (
                            <div
                              title={roundToLong(props.payload.impact.modelImpactPoints * forecastUnits)}
                              className='inline-flex items-center gap-1.5'
                            >
                              <span className='text-zinc-900 text-base'>
                                {simplify(props.payload.impact.modelImpactPoints * forecastUnits)}
                              </span>
                              <span className='uppercase text-zinc-500 tracking-widest text-[10px]'>Impact points</span>
                            </div>
                          ) : (
                            <div className='uppercase text-zinc-800 tracking-widest text-[10px]'>No units entered</div>
                          )}
                        </div>
                        <div
                          className={cn(
                            'flex justify-center items-center gap-1.5 rounded-2xl text-base px-4',
                            {
                              [ImpactDeltaType.Higher]: 'text-red-500 bg-red-50',
                              [ImpactDeltaType.Lower]: 'text-emerald-700 bg-emerald-50',
                              [ImpactDeltaType.Zero]: 'text-zinc-600 bg-zinc-100',
                            }[forecastUnits === '' ? ImpactDeltaType.Zero : forecastSelectedCategory.impactDeltaPhysical.type],
                          )}
                        >
                          <div
                            className={cn(
                              'flex justify-center items-center size-4 rounded-full text-xs',
                              {
                                [ImpactDeltaType.Higher]: 'bg-red-500 text-red-50',
                                [ImpactDeltaType.Lower]: 'bg-emerald-700 text-emerald-50',
                                [ImpactDeltaType.Zero]: 'bg-zinc-400 text-zinc-100',
                              }[forecastUnits === '' ? ImpactDeltaType.Zero : forecastSelectedCategory.impactDeltaPhysical.type],
                            )}
                          >
                            <FontAwesomeIcon
                              size='xs'
                              icon={
                                {
                                  [ImpactDeltaType.Higher]: solid('arrow-up'),
                                  [ImpactDeltaType.Lower]: solid('arrow-down'),
                                  [ImpactDeltaType.Zero]: solid('arrow-right'),
                                }[forecastUnits === '' ? ImpactDeltaType.Zero : forecastSelectedCategory.impactDeltaPhysical.type]
                              }
                            />
                          </div>
                          <div className='flex gap-1 items-center'>
                            <div>
                              {forecastUnits === ''
                                ? 0
                                : formatPhysicalImpact(forecastSelectedCategory.impactDeltaPhysical.raw * forecastUnits)}
                            </div>
                            <div className='text-[10px]'>{forecastSelectedCategory.unit}</div>
                          </div>
                        </div>
                      </div>
                      <div className='flex flex-col gap-3 px-1 mt-2 text-xs'>
                        <div className='font-semibold'>Impact change by category</div>
                        <div className='grid grid-cols-[max-content_theme(width.20)_repeat(2,max-content)] gap-x-4 items-center'>
                          {props.payload.impact.categories.map((category) => (
                            <Fragment key={category.id}>
                              <div className='text-zinc-800 py-1'>{category.name}</div>
                              <div className='flex h-full'>
                                <div className='flex-1 py-1 flex justify-end'>
                                  <div
                                    className='relative left-px bg-emerald-50 border border-emerald-700 transition-[width,opacity]'
                                    style={(() => {
                                      const minMinusDelta = min(
                                        props.payload.impact.categories
                                          .map(({ impactDeltaPhysical: { raw } }) => raw)
                                          .filter((value) => value < 0),
                                      );
                                      return minMinusDelta === undefined || category.impactDeltaPhysical.raw >= 0 || forecastUnits === ''
                                        ? { opacity: 0, width: '0%' }
                                        : { width: `${(category.impactDeltaPhysical.raw / minMinusDelta) * 100}%` };
                                    })()}
                                  ></div>
                                </div>
                                <div className='w-px bg-zinc-500 z-[1]'></div>
                                <div className='flex-1 py-1 flex'>
                                  <div
                                    className='relative right-px bg-red-50 border border-red-500 transition-[width,opacity]'
                                    style={(() => {
                                      const maxPlusDelta = max(
                                        props.payload.impact.categories
                                          .map(({ impactDeltaPhysical: { raw } }) => raw)
                                          .filter((value) => value > 0),
                                      );
                                      return maxPlusDelta === undefined || category.impactDeltaPhysical.raw <= 0 || forecastUnits === ''
                                        ? { opacity: 0, width: '0%' }
                                        : { width: `${(category.impactDeltaPhysical.raw / maxPlusDelta) * 100}%` };
                                    })()}
                                  ></div>
                                </div>
                              </div>
                              <div
                                className={cn(
                                  'flex justify-end gap-1 min-w-[50px]',
                                  forecastUnits !== '' && category.impactDeltaPhysical.type === ImpactDeltaType.Higher
                                    ? 'text-red-500'
                                    : 'text-zinc-900',
                                )}
                              >
                                {forecastUnits === '' ? 0 : formatPhysicalImpact(category.impactDeltaPhysical.raw * forecastUnits)}
                              </div>
                              <div className='text-[10px]'>{category.unit}</div>
                            </Fragment>
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        ref={scrollableRef}
        className={cn(
          'relative overflow-scroll h-[calc(100vh-theme(spacing.1)*60.25)]',
          '-mx-6 xl:-mx-[calc((100vw-theme(screens.xl))/2+theme(spacing.6))]',
          'flex bg-slate-50 border-t border-slate-100',
        )}
      >
        {(() => {
          const padding = 'w-[calc((100vw-theme(screens.xl))/2)] shrink-0';

          return (
            <>
              <div className={padding}></div>
              <div className='flex-1 flex flex-col'>
                {innerView?.name === InnerView.ProductionFacility && (
                  <ProductionFacilityGraph
                    payload={payload}
                    data={formik.values.nodes.find(({ id }) => id === innerView.nodeId) as ProductionNode}
                    scrollableRef={scrollableRef}
                    waiting={waiting}
                    ignoreLessImportantValidation={ignoreLessImportantValidation}
                    readOnlyMode={readOnlyMode}
                    onBack={() => setInnerView(undefined)}
                  />
                )}
                {innerView?.name === InnerView.Storage && (
                  <StorageItems
                    payload={payload}
                    data={formik.values.nodes.find(({ id }) => id === innerView.nodeId) as StorageNode}
                    replace={replace}
                    readOnlyMode={readOnlyMode}
                    onBack={() => setInnerView(undefined)}
                  />
                )}
                {innerView?.name === InnerView.ConsumptionLocation && (
                  <ConsumptionLocationGraph
                    payload={payload}
                    data={formik.values.nodes.find(({ id }) => id === innerView.nodeId) as ConsumptionNode}
                    scrollableRef={scrollableRef}
                    waiting={waiting}
                    ignoreLessImportantValidation={ignoreLessImportantValidation}
                    readOnlyMode={readOnlyMode}
                    onBack={() => setInnerView(undefined)}
                  />
                )}
                {!innerView && (
                  <>
                    <div
                      className={cn('flex-1 text-xs px-12 grid gap-x-3', '[--default-column-width:theme(width.64)]', {
                        'grid-cols-[min(var(--default-column-width))_repeat(2,max-content)_min(theme(width.52))_min(var(--default-column-width))]':
                          final,
                        'grid-cols-[min(var(--default-column-width))_repeat(2,max-content)]': intermediate,
                        'grid-cols-[min(var(--default-column-width))_repeat(1,max-content)]': internal,
                        'cursor-move': dragState?.inProgress,
                      })}
                    >
                      <div className='flex flex-col rounded-lg bg-white'>
                        <div className='flex flex-col justify-center items-center gap-4 py-4 rounded-t-lg bg-[#E8EAF5]'>
                          <div className='text-zinc-700 font-semibold'>Raw Materials</div>
                          <div
                            className={cn('flex gap-2', {
                              invisible: readOnlyMode,
                            })}
                          >
                            <IngredientDetails
                              payload={payload}
                              readOnlyMode={readOnlyMode}
                              onSave={({ values, closeModal }) => {
                                add(values);
                                closeModal();
                              }}
                            >
                              <button
                                type='button'
                                className='group relative flex justify-center items-center gap-1 rounded-lg p-2 text-green-900 bg-green-50 shadow-[0_0_2px_0_rgba(0,0,0,0.2)] active:scale-95'
                              >
                                <FontAwesomeIcon icon={duotone('carrot')} className='group-hover:invisible' />
                                <div className='group-hover:invisible'>Add ingredient</div>
                                <FontAwesomeIcon icon={solid('plus')} className='absolute text-base hidden group-hover:block' />
                              </button>
                            </IngredientDetails>
                            <PackagingDetails
                              payload={payload}
                              readOnlyMode={readOnlyMode}
                              onSave={({ values, closeModal }) => {
                                add(values);
                                closeModal();
                              }}
                            >
                              <button
                                type='button'
                                className='group relative flex justify-center items-center gap-1 rounded-lg p-2 text-lime-900 bg-lime-50 shadow-[0_0_2px_0_rgba(0,0,0,0.2)] active:scale-95'
                              >
                                <FontAwesomeIcon icon={duotone('box-open')} className='group-hover:invisible' />
                                <div ref={addPackagingRef} className='group-hover:invisible'>
                                  Add packaging
                                </div>
                                <FontAwesomeIcon icon={solid('plus')} className='absolute text-base hidden group-hover:block' />
                              </button>
                            </PackagingDetails>
                          </div>
                        </div>
                        <div ref={lifeCycleBoxRefs.current[LifeCycleStage.RawMaterials]} className='flex flex-col gap-5 p-6'>
                          {getIngredientsWithDeleted(formik, payload, false).length === 0 &&
                            getPackagingsWithDeleted(formik, payload, false).length === 0 && (
                              <div className='text-zinc-400 text-center'>
                                Add all the ingredients and packaging you source to make your final product. As many as you need.
                              </div>
                            )}
                          {sortAndFilterNodes(getIngredientsWithDeleted(formik, payload, readOnlyMode)).map((ingredient) => (
                            <IngredientDetails
                              key={ingredient.id}
                              payload={payload}
                              data={ingredient}
                              readOnlyMode={readOnlyMode}
                              onSave={({ values, sideEffects, closeModal }) => {
                                replace(values, sideEffects);
                                closeModal();
                              }}
                            >
                              <div className={cn({ 'pointer-events-none': waiting || isDeleted(ingredient) })}>
                                <div
                                  ref={nodeRefs.current[ingredient.id]}
                                  className={cn(
                                    'text-zinc-700 cursor-pointer flex-1 flex flex-col gap-3 bg-white rounded-xl p-1.5',
                                    'transition',
                                    getBorderStyle(ingredient),
                                    {
                                      'hover:bg-green-50': hoveredMenuButtonNodeId !== ingredient.id,
                                    },
                                  )}
                                >
                                  <div className='flex gap-0.5 items-center justify-between'>
                                    <div
                                      className={cn('flex gap-2 items-center truncate', {
                                        'opacity-50': isDeleted(ingredient),
                                      })}
                                    >
                                      <div className='relative flex justify-center items-center bg-green-50 text-green-900 rounded-md h-7 aspect-square'>
                                        <FontAwesomeIcon icon={duotone('carrot')} />
                                        {ingredient.ingredient.type === IngredientType.IntermediateProduct && (
                                          <FontAwesomeIcon
                                            icon={solid('circle-arrow-down')}
                                            className='text-[7px] absolute right-[7px] bottom-[7px]'
                                          />
                                        )}
                                      </div>
                                      <div className='font-semibold truncate'>{ingredient.displayName}</div>
                                    </div>
                                    {!readOnlyMode && !isDeleted(ingredient) && (
                                      <div onClick={(event) => event.stopPropagation()}>
                                        <Menu
                                          zIndex={20}
                                          placement='bottom-start'
                                          items={[
                                            ...(ingredient.nodes.every(({ edges }) => edges.length > 0)
                                              ? []
                                              : [
                                                  {
                                                    icon: regular('truck'),
                                                    label: 'Add transport link',
                                                    modal: (button, onOpenChange) => (
                                                      <TransportDetails
                                                        fromId={ingredient.id}
                                                        readOnlyMode={readOnlyMode}
                                                        payload={payload}
                                                        onOpenChange={onOpenChange}
                                                        onSave={({ values, fromId, closeModal }) => {
                                                          addReplaceTransport(values, fromId);
                                                          closeModal();
                                                        }}
                                                      >
                                                        {button}
                                                      </TransportDetails>
                                                    ),
                                                  } as MenuItemConfig,
                                                ]),
                                            {
                                              icon: regular('clone'),
                                              label: 'Duplicate',
                                              onClick: () => duplicate(ingredient),
                                            },
                                            {
                                              icon: regular('pencil'),
                                              label: 'Edit',
                                              modal: (button, onOpenChange) => (
                                                <IngredientDetails
                                                  onOpenChange={onOpenChange}
                                                  key={ingredient.id}
                                                  payload={payload}
                                                  data={ingredient}
                                                  readOnlyMode={readOnlyMode}
                                                  onSave={({ values, sideEffects, closeModal }) => {
                                                    replace(values, sideEffects);
                                                    closeModal();
                                                  }}
                                                >
                                                  {button}
                                                </IngredientDetails>
                                              ),
                                            },
                                            ...(ingredient.ingredient.type !== IngredientType.IntermediateProduct
                                              ? []
                                              : [
                                                  {
                                                    icon: regular('arrow-up-right-from-square'),
                                                    label: 'Edit internal product',
                                                    onClick: () =>
                                                      window.open(routes.products.productGraph(ingredient.ingredient.id), '_blank'),
                                                  },
                                                ]),
                                            {
                                              icon: regular('trash-can'),
                                              label: 'Delete',
                                              onClick: () => remove(ingredient),
                                            },
                                          ]}
                                        >
                                          {({ open }) => (
                                            <button
                                              type='button'
                                              onMouseEnter={() => setHoveredMenuButtonNodeId(ingredient.id)}
                                              onMouseLeave={() => setHoveredMenuButtonNodeId('')}
                                              className={cn('flex hover:text-inherit', { 'text-zinc-400': !open })}
                                            >
                                              <FontAwesomeIcon className='h-4 aspect-square' icon={solid('ellipsis-vertical')} />
                                            </button>
                                          )}
                                        </Menu>
                                      </div>
                                    )}
                                  </div>
                                  <div className={cn('flex flex-col gap-3', { 'opacity-50': isDeleted(ingredient) })}>
                                    {sortAndFilterNodes(getIngredientSuppliersWithDeleted(ingredient, payload, readOnlyMode)).map(
                                      (supplier) => (
                                        <div
                                          key={supplier.id}
                                          ref={nodeRefs.current[supplier.id]}
                                          title={supplier.displayName}
                                          className={cn(
                                            'flex flex-col gap-1.5 bg-zinc-50 p-1.5 rounded-md',
                                            getSupplierBorderStyle(supplier),
                                          )}
                                        >
                                          <div
                                            className={cn('truncate', {
                                              'opacity-50': isDeleted(supplier),
                                            })}
                                          >
                                            {supplier.displayName}
                                          </div>
                                          {renderNodeBadges(supplier, { noDelta: true })}
                                        </div>
                                      ),
                                    )}
                                  </div>
                                  {renderNodeBadges(ingredient)}
                                </div>
                              </div>
                            </IngredientDetails>
                          ))}
                          {sortAndFilterNodes(getPackagingsWithDeleted(formik, payload, readOnlyMode)).map((packaging) => (
                            <PackagingDetails
                              key={packaging.id}
                              payload={payload}
                              data={packaging}
                              readOnlyMode={readOnlyMode}
                              onSave={({ values, sideEffects, closeModal }) => {
                                replace(values, sideEffects);
                                closeModal();
                              }}
                            >
                              <div className={cn({ 'pointer-events-none': waiting || isDeleted(packaging) })}>
                                <div
                                  ref={nodeRefs.current[packaging.id]}
                                  className={cn(
                                    'relative text-zinc-700 cursor-pointer flex-1 flex flex-col gap-3 bg-white rounded-xl p-1.5',
                                    'transition',
                                    getBorderStyle(packaging),
                                    {
                                      'hover:bg-lime-50': hoveredMenuButtonNodeId !== packaging.id,
                                    },
                                  )}
                                >
                                  <div className='flex gap-0.5 items-center justify-between'>
                                    <div
                                      className={cn('flex gap-2 items-center truncate', {
                                        'opacity-50': isDeleted(packaging),
                                      })}
                                    >
                                      <div className='flex justify-center items-center bg-lime-50 text-lime-900 rounded-md h-7 aspect-square'>
                                        <FontAwesomeIcon icon={duotone('box-open')} />
                                      </div>
                                      <div className='font-semibold truncate'>{packaging.displayName}</div>
                                    </div>
                                    {!readOnlyMode && !isDeleted(packaging) && (
                                      <div onClick={(event) => event.stopPropagation()}>
                                        <Menu
                                          zIndex={20}
                                          placement='bottom-start'
                                          items={[
                                            ...(packaging.nodes.every(({ edges }) => edges.length > 0)
                                              ? []
                                              : [
                                                  {
                                                    icon: regular('truck'),
                                                    label: 'Add transport',
                                                    modal: (button, onOpenChange) => (
                                                      <TransportDetails
                                                        fromId={packaging.id}
                                                        readOnlyMode={readOnlyMode}
                                                        payload={payload}
                                                        onOpenChange={onOpenChange}
                                                        onSave={({ values, fromId, closeModal }) => {
                                                          addReplaceTransport(values, fromId);
                                                          closeModal();
                                                        }}
                                                      >
                                                        {button}
                                                      </TransportDetails>
                                                    ),
                                                  } as MenuItemConfig,
                                                ]),
                                            {
                                              icon: regular('clone'),
                                              label: 'Duplicate',
                                              onClick: () => duplicate(packaging),
                                            },
                                            {
                                              icon: regular('pencil'),
                                              label: 'Edit',
                                              modal: (button, onOpenChange) => (
                                                <PackagingDetails
                                                  onOpenChange={onOpenChange}
                                                  key={packaging.id}
                                                  payload={payload}
                                                  data={packaging}
                                                  readOnlyMode={readOnlyMode}
                                                  onSave={({ values, sideEffects, closeModal }) => {
                                                    replace(values, sideEffects);
                                                    closeModal();
                                                  }}
                                                >
                                                  {button}
                                                </PackagingDetails>
                                              ),
                                            },
                                            {
                                              icon: regular('trash-can'),
                                              label: 'Delete',
                                              onClick: () => remove(packaging),
                                            },
                                          ]}
                                        >
                                          {({ open }) => (
                                            <button
                                              type='button'
                                              onMouseEnter={() => setHoveredMenuButtonNodeId(packaging.id)}
                                              onMouseLeave={() => setHoveredMenuButtonNodeId('')}
                                              className={cn('flex hover:text-inherit', { 'text-zinc-400': !open })}
                                            >
                                              <FontAwesomeIcon className='h-4 aspect-square' icon={solid('ellipsis-vertical')} />
                                            </button>
                                          )}
                                        </Menu>
                                      </div>
                                    )}
                                  </div>
                                  <div
                                    className={cn('flex flex-col gap-3', {
                                      'opacity-50': isDeleted(packaging),
                                    })}
                                  >
                                    {sortAndFilterNodes(getPackagingSuppliersWithDeleted(packaging, payload, readOnlyMode)).map(
                                      (supplier) => (
                                        <div
                                          key={supplier.id}
                                          ref={nodeRefs.current[supplier.id]}
                                          title={supplier.displayName}
                                          className={cn(
                                            'flex flex-col gap-1.5 bg-zinc-50 p-1.5 rounded-md',
                                            getSupplierBorderStyle(supplier),
                                          )}
                                        >
                                          <div
                                            className={cn('truncate', {
                                              'opacity-50': isDeleted(supplier),
                                            })}
                                          >
                                            {supplier.displayName}
                                          </div>
                                          {renderNodeBadges(supplier, { noDelta: true })}
                                        </div>
                                      ),
                                    )}
                                  </div>
                                  {renderNodeBadges(packaging)}
                                </div>
                              </div>
                            </PackagingDetails>
                          ))}
                        </div>
                      </div>
                      <div className='flex flex-col rounded-lg bg-white'>
                        {(() => {
                          const shorten =
                            formik.values.layoutGrids.production.width < 2 ||
                            (getProductionFacilitiesWithDeleted(formik, payload, false).length === 0 &&
                              getProductionWarehousesWithDeleted(formik, payload, false).length === 0);
                          return (
                            <div className='flex flex-col justify-center items-center gap-4 py-4 rounded-t-lg bg-[#E8EAF5]'>
                              <div className='text-zinc-700 font-semibold'>Production</div>
                              <div
                                className={cn('flex w-full', {
                                  invisible: readOnlyMode,
                                })}
                              >
                                <div className='flex-1'>
                                  <GridSize modelName='layoutGrids.production'>
                                    {() => (
                                      <button
                                        type='button'
                                        className={cn(
                                          shorten ? 'ml-2' : 'ml-3',
                                          shorten ? 'w-5' : 'aspect-square',
                                          'flex justify-center items-center rounded-lg h-full',
                                          'text-[#220066] bg-[#DACEFD] shadow-[0_0_2px_0_rgba(0,0,0,0.2)]',
                                          'active:scale-95',
                                        )}
                                      >
                                        <FontAwesomeIcon size={shorten ? 'sm' : 'lg'} icon={regular('table')} />
                                      </button>
                                    )}
                                  </GridSize>
                                </div>
                                <div className='flex gap-2'>
                                  <ProductionFacilityDetails
                                    payload={payload}
                                    readOnlyMode={readOnlyMode}
                                    onSave={({ values, closeModal }) => {
                                      add(values);
                                      closeModal();
                                    }}
                                  >
                                    <button
                                      type='button'
                                      className='group relative flex shrink-0 justify-center items-center gap-1 rounded-lg p-2 text-rose-900 bg-rose-50 shadow-[0_0_2px_0_rgba(0,0,0,0.2)] active:scale-95'
                                    >
                                      {!shorten && <FontAwesomeIcon icon={duotone('industry-windows')} className='group-hover:invisible' />}
                                      <div className='group-hover:invisible'>{shorten ? 'Facility' : 'Add production facility'}</div>
                                      <FontAwesomeIcon icon={solid('plus')} className='absolute text-base hidden group-hover:block' />
                                    </button>
                                  </ProductionFacilityDetails>
                                  <ProductionWarehouseDetails
                                    payload={payload}
                                    readOnlyMode={readOnlyMode}
                                    onSave={({ values, closeModal }) => {
                                      addAll(values);
                                      closeModal();
                                    }}
                                  >
                                    <button
                                      type='button'
                                      className='group relative flex shrink-0 justify-center items-center gap-1 rounded-lg p-2 text-fuchsia-900 bg-fuchsia-50 shadow-[0_0_2px_0_rgba(0,0,0,0.2)] active:scale-95'
                                    >
                                      {!shorten && <FontAwesomeIcon icon={duotone('warehouse-full')} className='group-hover:invisible' />}
                                      <div className='group-hover:invisible'>{shorten ? 'Storage' : 'Add storage'}</div>
                                      <FontAwesomeIcon icon={solid('plus')} className='absolute text-base hidden group-hover:block' />
                                    </button>
                                  </ProductionWarehouseDetails>
                                </div>
                                <div className='flex-1'></div>
                              </div>
                            </div>
                          );
                        })()}
                        <div
                          ref={lifeCycleBoxRefs.current[LifeCycleStage.Production]}
                          className={cn(
                            'p-6',
                            getProductionFacilitiesWithDeleted(formik, payload, false).length +
                              getProductionWarehousesWithDeleted(formik, payload, false).length >
                              0
                              ? 'grid gap-8'
                              : 'w-52 flex flex-col',
                          )}
                          style={{
                            gridTemplateColumns: `repeat(${formik.values.layoutGrids.production.width}, min(10rem))`,
                          }}
                        >
                          {getProductionFacilitiesWithDeleted(formik, payload, false).length === 0 &&
                          getProductionWarehousesWithDeleted(formik, payload, false).length === 0 ? (
                            <div className='text-zinc-400 text-center'>
                              Where is your final product getting produced? Whether it’s all happening in one place or more, add it in!
                            </div>
                          ) : (
                            (() => {
                              const openDetails = (node: ProductionNode | ProductionWarehouseNode | undefined, children: ReactNode) =>
                                node ? (
                                  node.type === NodeType.Production ? (
                                    <ProductionFacilityDetails
                                      key={node.id}
                                      payload={payload}
                                      data={node}
                                      readOnlyMode={readOnlyMode}
                                      onSave={({ values, closeModal }) => {
                                        replace(values);
                                        closeModal();
                                      }}
                                    >
                                      {children}
                                    </ProductionFacilityDetails>
                                  ) : (
                                    <ProductionWarehouseDetails
                                      key={node.id}
                                      payload={payload}
                                      data={node}
                                      readOnlyMode={readOnlyMode}
                                      onSave={({ values, closeModal }) => {
                                        replace(values[0]);
                                        closeModal();
                                      }}
                                    >
                                      {children}
                                    </ProductionWarehouseDetails>
                                  )
                                ) : (
                                  children
                                );

                              const render = (position?: Position, node?: ProductionNode | ProductionWarehouseNode) =>
                                openDetails(
                                  node,
                                  <div
                                    key={position ? `${position.x}.${position.y}` : node!.id}
                                    className={cn({ 'pointer-events-none': waiting || isDeleted(node), flex: !node })}
                                  >
                                    <div
                                      ref={node ? nodeRefs.current[node.id] : productionSlotsRefs.current[`${position!.x}.${position!.y}`]}
                                      className={cn(
                                        'select-none text-zinc-700 flex-1 flex flex-col gap-3 rounded-xl p-1.5',
                                        'group/outer',
                                        getBorderStyle(node),
                                        {
                                          transition: node,
                                          [node?.type === NodeType.ProductionWarehouse
                                            ? 'cursor-pointer hover:bg-fuchsia-50'
                                            : 'cursor-pointer hover:bg-rose-50']:
                                            node &&
                                            !dragState?.inProgress &&
                                            ![hoveredInnerViewButtonNodeId, hoveredMenuButtonNodeId].includes(node.id),
                                          [node?.type === NodeType.ProductionWarehouse ? 'bg-fuchsia-50' : 'bg-rose-50']:
                                            dragState?.inProgress && dragState.nodeId === node?.id,
                                          'bg-white': node && !(dragState?.inProgress && dragState.nodeId === node?.id),
                                          'bg-neutral-50': !node,
                                        },
                                      )}
                                      onMouseDown={
                                        node && !readOnlyMode
                                          ? (event) => {
                                              setDragState({
                                                mouseDown: { x: event.clientX, y: event.clientY },
                                                nodeId: node.id,
                                                inProgress: false,
                                              });
                                            }
                                          : undefined
                                      }
                                    >
                                      <div className='flex gap-0.5 items-center justify-between'>
                                        <div
                                          className={cn('flex gap-2 items-center truncate', {
                                            'opacity-50': isDeleted(node),
                                          })}
                                        >
                                          <div
                                            className={cn(
                                              'flex justify-center items-center rounded-md h-7 aspect-square',
                                              node?.type === NodeType.ProductionWarehouse
                                                ? 'bg-fuchsia-50 text-fuchsia-900'
                                                : 'bg-rose-50 text-rose-900',
                                              {
                                                invisible: !node,
                                              },
                                            )}
                                          >
                                            <FontAwesomeIcon
                                              icon={
                                                node?.type === NodeType.ProductionWarehouse
                                                  ? duotone('warehouse-full')
                                                  : duotone('industry-windows')
                                              }
                                            />
                                          </div>
                                          <div className='flex flex-col gap-0.5 justify-center truncate'>
                                            <div className='font-semibold truncate'>{node?.displayName ?? <>&nbsp;</>}</div>
                                            {node && !isDeleted(node) && node.type === NodeType.Production && node.finalFacility && (
                                              <div className='text-[8px] leading-none tracking-wider font-semibold text-rose-900 uppercase'>
                                                Final
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        {node && !readOnlyMode && !isDeleted(node) && (
                                          <div onClick={(event) => event.stopPropagation()}>
                                            <Menu
                                              zIndex={20}
                                              placement='bottom-start'
                                              items={[
                                                {
                                                  icon: regular('truck'),
                                                  label: 'Add transport link',
                                                  modal: (button, onOpenChange) => (
                                                    <TransportDetails
                                                      fromId={node.id}
                                                      onOpenChange={onOpenChange}
                                                      payload={payload}
                                                      readOnlyMode={readOnlyMode}
                                                      onSave={({ values, fromId, closeModal }) => {
                                                        addReplaceTransport(values, fromId);
                                                        closeModal();
                                                      }}
                                                    >
                                                      {button}
                                                    </TransportDetails>
                                                  ),
                                                },
                                                {
                                                  icon: regular('pencil'),
                                                  label: 'Edit',
                                                  modal: (button, onOpenChange) =>
                                                    node.type === NodeType.Production ? (
                                                      <ProductionFacilityDetails
                                                        onOpenChange={onOpenChange}
                                                        key={node.id}
                                                        payload={payload}
                                                        data={node}
                                                        readOnlyMode={readOnlyMode}
                                                        onSave={({ values, closeModal }) => {
                                                          replace(values);
                                                          closeModal();
                                                        }}
                                                      >
                                                        {button}
                                                      </ProductionFacilityDetails>
                                                    ) : (
                                                      <ProductionWarehouseDetails
                                                        onOpenChange={onOpenChange}
                                                        key={node.id}
                                                        payload={payload}
                                                        data={node}
                                                        readOnlyMode={readOnlyMode}
                                                        onSave={({ values, closeModal }) => {
                                                          replace(values[0]);
                                                          closeModal();
                                                        }}
                                                      >
                                                        {button}
                                                      </ProductionWarehouseDetails>
                                                    ),
                                                },
                                                {
                                                  icon: regular('trash-can'),
                                                  label: 'Delete',
                                                  onClick: () => remove(node),
                                                },
                                              ]}
                                            >
                                              {({ open }) => (
                                                <button
                                                  type='button'
                                                  onMouseEnter={() => setHoveredMenuButtonNodeId(node.id)}
                                                  onMouseLeave={() => setHoveredMenuButtonNodeId('')}
                                                  className={cn('flex hover:text-inherit', { 'text-zinc-400': !open })}
                                                >
                                                  <FontAwesomeIcon className='h-4 aspect-square' icon={solid('ellipsis-vertical')} />
                                                </button>
                                              )}
                                            </Menu>
                                          </div>
                                        )}
                                      </div>
                                      {!isDeleted(node) && (
                                        <button
                                          type='button'
                                          className={cn(
                                            'flex flex-col gap-1.5 px-1.5 py-1 rounded-md',
                                            'border',
                                            node?.type === NodeType.Production ? 'bg-rose-50' : 'bg-fuchsia-50',
                                            !readOnlyMode &&
                                              node?.type === NodeType.Production &&
                                              node.steps.some((step) =>
                                                hasValidationMessage(step, formik, { ignoreLessImportant: ignoreLessImportantValidation }),
                                              )
                                              ? 'border-red-500'
                                              : 'border-transparent',
                                            'group/inner',
                                            {
                                              invisible: !node,
                                              [cn(
                                                node?.type === NodeType.Production ? 'hover:bg-rose-200' : 'hover:bg-fuchsia-200',
                                                'hover:shadow-[0_0_2px_0_rgba(0,0,0,0.2)]',
                                              )]: node && !dragState?.inProgress,
                                              'group-hover/outer:bg-neutral-50':
                                                node && !dragState?.inProgress && hoveredInnerViewButtonNodeId !== node.id,
                                              'cursor-move': dragState?.inProgress,
                                            },
                                          )}
                                          onMouseEnter={() => setHoveredInnerViewButtonNodeId(node!.id)}
                                          onMouseLeave={() => setHoveredInnerViewButtonNodeId('')}
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            savedScroll.current = {
                                              left: scrollableRef.current!.scrollLeft,
                                              top: scrollableRef.current!.scrollTop,
                                            };
                                            setInnerView({
                                              name: node?.type === NodeType.Production ? InnerView.ProductionFacility : InnerView.Storage,
                                              nodeId: node!.id,
                                            });
                                          }}
                                        >
                                          <div className='flex gap-1.5 items-center'>
                                            <div
                                              className={cn(
                                                'rounded-2xl px-1.5 py-0.5 bg-white ',
                                                node?.type === NodeType.Production ? 'text-rose-900' : 'text-fuchsia-900',
                                                {
                                                  'group-hover/outer:text-inherit':
                                                    node && !dragState?.inProgress && hoveredInnerViewButtonNodeId !== node.id,
                                                  [node?.type === NodeType.Production
                                                    ? 'group-hover/inner:text-rose-900'
                                                    : 'group-hover/inner:text-fuchsia-900']: node && !dragState?.inProgress,
                                                },
                                              )}
                                            >
                                              {node ? (node.type === NodeType.Production ? node.steps : node.items).length : 0}
                                            </div>
                                            <div
                                              className={cn({
                                                'group-hover/outer:text-zinc-500':
                                                  node && !dragState?.inProgress && hoveredInnerViewButtonNodeId !== node.id,
                                                'group-hover/inner:text-inherit': node && !dragState?.inProgress,
                                              })}
                                            >
                                              {node ? (
                                                node.type === NodeType.Production ? (
                                                  <>Production step{node.steps.length === 1 ? '' : 's'}</>
                                                ) : (
                                                  <>Stored item{node.items.length === 1 ? '' : 's'}</>
                                                )
                                              ) : (
                                                <>&nbsp;</>
                                              )}
                                            </div>
                                          </div>
                                          {renderInnerViewBadges(node)}
                                        </button>
                                      )}
                                      {renderNodeBadges(node)}
                                    </div>
                                  </div>,
                                );

                              return (
                                <>
                                  {sizeToPositions(formik.values.layoutGrids.production).map((position) =>
                                    render(
                                      position,
                                      sortAndFilterNodes([
                                        ...getProductionFacilitiesWithDeleted(formik, payload, readOnlyMode),
                                        ...getProductionWarehousesWithDeleted(formik, payload, readOnlyMode),
                                      ]).find((node) => node.layoutPosition?.x === position.x && node.layoutPosition?.y === position.y),
                                    ),
                                  )}
                                  {sortAndFilterNodes([
                                    ...getProductionFacilitiesWithDeleted(formik, payload, readOnlyMode),
                                    ...getProductionWarehousesWithDeleted(formik, payload, readOnlyMode),
                                  ])
                                    .filter(
                                      (node) =>
                                        !node.layoutPosition ||
                                        node.layoutPosition.x >= formik.values.layoutGrids.production.width ||
                                        node.layoutPosition.y >= formik.values.layoutGrids.production.height,
                                    )
                                    .map((node) => render(undefined, node))}
                                </>
                              );
                            })()
                          )}
                        </div>
                      </div>
                      {(final || intermediate) && (
                        <>
                          <div className='flex flex-col rounded-lg bg-white'>
                            {(() => {
                              const shorten =
                                formik.values.layoutGrids.distribution.width < 2 ||
                                (getWarehousesWithDeleted(formik, payload, false).length === 0 &&
                                  getStoresWithDeleted(formik, payload, false).length === 0 &&
                                  getFinalDestinationsWithDeleted(formik, payload, false).length === 0);
                              return (
                                <div className='flex flex-col justify-center items-center gap-4 py-4 rounded-t-lg bg-[#E8EAF5]'>
                                  <div className='text-zinc-700 font-semibold'>Distribution</div>
                                  <div
                                    className={cn('flex w-full', {
                                      invisible: readOnlyMode,
                                    })}
                                  >
                                    <div className='flex-1'>
                                      <GridSize modelName='layoutGrids.distribution'>
                                        {() => (
                                          <button
                                            type='button'
                                            className={cn(
                                              shorten ? 'ml-2' : 'ml-3',
                                              shorten ? 'w-5' : 'aspect-square',
                                              'flex justify-center items-center rounded-lg h-full',
                                              'text-[#220066] bg-[#DACEFD] shadow-[0_0_2px_0_rgba(0,0,0,0.2)]',
                                              'active:scale-95',
                                            )}
                                          >
                                            <FontAwesomeIcon size={shorten ? 'sm' : 'lg'} icon={regular('table')} />
                                          </button>
                                        )}
                                      </GridSize>
                                    </div>
                                    <div className='flex gap-2'>
                                      <WarehouseDetails
                                        payload={payload}
                                        readOnlyMode={readOnlyMode}
                                        onSave={({ values, closeModal }) => {
                                          addAll(values);
                                          closeModal();
                                        }}
                                      >
                                        <button
                                          type='button'
                                          className='group relative flex shrink-0 justify-center items-center gap-1 rounded-lg p-2 text-fuchsia-900 bg-fuchsia-50 shadow-[0_0_2px_0_rgba(0,0,0,0.2)] active:scale-95'
                                        >
                                          {!shorten && (
                                            <FontAwesomeIcon icon={duotone('warehouse-full')} className='group-hover:invisible' />
                                          )}
                                          <div ref={addWarehouseRef} className='group-hover:invisible'>
                                            {shorten ? 'Storage' : 'Add storage'}
                                          </div>
                                          <FontAwesomeIcon icon={solid('plus')} className='absolute text-base hidden group-hover:block' />
                                        </button>
                                      </WarehouseDetails>
                                      {intermediate ? (
                                        <FinalDestinationDetails
                                          payload={payload}
                                          readOnlyMode={readOnlyMode}
                                          onSave={({ values, closeModal }) => {
                                            add(values);
                                            closeModal();
                                          }}
                                        >
                                          <button
                                            type='button'
                                            className='group relative flex shrink-0 justify-center items-center gap-1 rounded-lg p-2 text-blue-900 bg-blue-50 shadow-[0_0_2px_0_rgba(0,0,0,0.2)] active:scale-95'
                                          >
                                            {!shorten && (
                                              <FontAwesomeIcon icon={duotone('flag-checkered')} className='group-hover:invisible' />
                                            )}
                                            <div className='group-hover:invisible'>{shorten ? 'Final Des.' : 'Add final destination'}</div>
                                            <FontAwesomeIcon icon={solid('plus')} className='absolute text-base hidden group-hover:block' />
                                          </button>
                                        </FinalDestinationDetails>
                                      ) : (
                                        <StoreDetails
                                          payload={payload}
                                          readOnlyMode={readOnlyMode}
                                          onSave={({ values, closeModal }) => {
                                            addAll(values);
                                            closeModal();
                                          }}
                                        >
                                          <button
                                            type='button'
                                            className='group relative flex shrink-0 justify-center items-center gap-1 rounded-lg p-2 text-blue-900 bg-blue-50 shadow-[0_0_2px_0_rgba(0,0,0,0.2)] active:scale-95'
                                          >
                                            {!shorten && <FontAwesomeIcon icon={duotone('store')} className='group-hover:invisible' />}
                                            <div ref={addStoreRef} className='group-hover:invisible'>
                                              {shorten ? 'Store' : 'Add store'}
                                            </div>
                                            <FontAwesomeIcon icon={solid('plus')} className='absolute text-base hidden group-hover:block' />
                                          </button>
                                        </StoreDetails>
                                      )}
                                    </div>
                                    <div className='flex-1'></div>
                                  </div>
                                </div>
                              );
                            })()}
                            <div
                              ref={lifeCycleBoxRefs.current[LifeCycleStage.Distribution]}
                              className={cn(
                                'p-6',
                                getWarehousesWithDeleted(formik, payload, false).length +
                                  getStoresWithDeleted(formik, payload, false).length +
                                  getFinalDestinationsWithDeleted(formik, payload, false).length >
                                  0
                                  ? 'grid gap-8'
                                  : 'w-52 flex flex-col',
                              )}
                              style={{
                                gridTemplateColumns: `repeat(${formik.values.layoutGrids.distribution.width}, min(10rem))`,
                              }}
                            >
                              {getWarehousesWithDeleted(formik, payload, false).length === 0 &&
                              getStoresWithDeleted(formik, payload, false).length === 0 &&
                              getFinalDestinationsWithDeleted(formik, payload, false).length === 0 ? (
                                <div className='text-zinc-400 text-center'>
                                  Once your final product is ready for distribution, what are the various stops it makes on its way to the
                                  end consumer?
                                </div>
                              ) : (
                                (() => {
                                  const openDetails = (
                                    node: WarehouseNode | StoreNode | FinalDestinationNode | undefined,
                                    children: ReactNode,
                                  ) =>
                                    node ? (
                                      node.type === NodeType.Warehouse ? (
                                        <WarehouseDetails
                                          key={node.id}
                                          payload={payload}
                                          data={node}
                                          readOnlyMode={readOnlyMode}
                                          onSave={({ values, closeModal }) => {
                                            replace(values[0]);
                                            closeModal();
                                          }}
                                        >
                                          {children}
                                        </WarehouseDetails>
                                      ) : node.type === NodeType.Store ? (
                                        <StoreDetails
                                          key={node.id}
                                          payload={payload}
                                          data={node}
                                          readOnlyMode={readOnlyMode}
                                          onSave={({ values, closeModal }) => {
                                            replace(values[0]);
                                            closeModal();
                                          }}
                                        >
                                          {children}
                                        </StoreDetails>
                                      ) : (
                                        <FinalDestinationDetails
                                          key={node.id}
                                          payload={payload}
                                          data={node}
                                          readOnlyMode={readOnlyMode}
                                          onSave={({ values, closeModal }) => {
                                            replace(values);
                                            closeModal();
                                          }}
                                        >
                                          {children}
                                        </FinalDestinationDetails>
                                      )
                                    ) : (
                                      children
                                    );

                                  const render = (position?: Position, node?: WarehouseNode | StoreNode | FinalDestinationNode) =>
                                    openDetails(
                                      node,
                                      <div
                                        key={position ? `${position.x}.${position.y}` : node!.id}
                                        className={cn({ 'pointer-events-none': waiting || isDeleted(node), flex: !node })}
                                      >
                                        <div
                                          ref={
                                            node ? nodeRefs.current[node.id] : productionSlotsRefs.current[`${position!.x}.${position!.y}`]
                                          }
                                          className={cn(
                                            'select-none text-zinc-700 flex-1 flex flex-col gap-3 rounded-xl p-1.5',
                                            'group/outer',
                                            getBorderStyle(node),
                                            {
                                              transition: node,
                                              [node?.type === NodeType.Warehouse
                                                ? 'cursor-pointer hover:bg-fuchsia-50'
                                                : 'cursor-pointer hover:bg-blue-50']:
                                                node &&
                                                !dragState?.inProgress &&
                                                ![hoveredInnerViewButtonNodeId, hoveredMenuButtonNodeId].includes(node.id),
                                              [node?.type === NodeType.Warehouse ? 'bg-fuchsia-50' : 'bg-blue-50']:
                                                dragState?.inProgress && dragState.nodeId === node?.id,
                                              'bg-white': node && !(dragState?.inProgress && dragState.nodeId === node?.id),
                                              'bg-neutral-50': !node,
                                            },
                                          )}
                                          onMouseDown={
                                            node && !readOnlyMode
                                              ? (event) => {
                                                  setDragState({
                                                    mouseDown: { x: event.clientX, y: event.clientY },
                                                    nodeId: node.id,
                                                    inProgress: false,
                                                  });
                                                }
                                              : undefined
                                          }
                                        >
                                          <div className='flex gap-0.5 items-center justify-between'>
                                            <div
                                              className={cn('flex gap-2 items-center truncate', {
                                                'opacity-50': isDeleted(node),
                                              })}
                                            >
                                              <div
                                                className={cn(
                                                  'flex justify-center items-center rounded-md h-7 aspect-square',
                                                  node?.type === NodeType.Warehouse
                                                    ? 'bg-fuchsia-50 text-fuchsia-900'
                                                    : 'bg-blue-50 text-blue-900',
                                                  {
                                                    invisible: !node,
                                                  },
                                                )}
                                              >
                                                <FontAwesomeIcon
                                                  icon={
                                                    node?.type === NodeType.Warehouse
                                                      ? duotone('warehouse-full')
                                                      : node?.type === NodeType.FinalDestination
                                                      ? duotone('flag-checkered')
                                                      : duotone('store')
                                                  }
                                                />
                                              </div>
                                              <div className='font-semibold truncate'>{node?.displayName ?? <>&nbsp;</>}</div>
                                            </div>
                                            {node && !readOnlyMode && !isDeleted(node) && (
                                              <div onClick={(event) => event.stopPropagation()}>
                                                <Menu
                                                  zIndex={20}
                                                  placement='bottom-start'
                                                  items={[
                                                    ...(node.type === NodeType.FinalDestination
                                                      ? []
                                                      : [
                                                          {
                                                            icon: regular('truck'),
                                                            label: 'Add transport link',
                                                            modal: (button, onOpenChange) => (
                                                              <TransportDetails
                                                                fromId={node.id}
                                                                onOpenChange={onOpenChange}
                                                                payload={payload}
                                                                readOnlyMode={readOnlyMode}
                                                                onSave={({ values, fromId, closeModal }) => {
                                                                  addReplaceTransport(values, fromId);
                                                                  closeModal();
                                                                }}
                                                              >
                                                                {button}
                                                              </TransportDetails>
                                                            ),
                                                          } as MenuItemConfig,
                                                        ]),
                                                    {
                                                      icon: regular('pencil'),
                                                      label: 'Edit',
                                                      modal: (button, onOpenChange) =>
                                                        node.type === NodeType.Warehouse ? (
                                                          <WarehouseDetails
                                                            onOpenChange={onOpenChange}
                                                            key={node.id}
                                                            payload={payload}
                                                            data={node}
                                                            readOnlyMode={readOnlyMode}
                                                            onSave={({ values, closeModal }) => {
                                                              replace(values[0]);
                                                              closeModal();
                                                            }}
                                                          >
                                                            {button}
                                                          </WarehouseDetails>
                                                        ) : node.type === NodeType.Store ? (
                                                          <StoreDetails
                                                            onOpenChange={onOpenChange}
                                                            key={node.id}
                                                            payload={payload}
                                                            data={node}
                                                            readOnlyMode={readOnlyMode}
                                                            onSave={({ values, closeModal }) => {
                                                              replace(values[0]);
                                                              closeModal();
                                                            }}
                                                          >
                                                            {button}
                                                          </StoreDetails>
                                                        ) : (
                                                          <FinalDestinationDetails
                                                            onOpenChange={onOpenChange}
                                                            key={node.id}
                                                            payload={payload}
                                                            data={node}
                                                            readOnlyMode={readOnlyMode}
                                                            onSave={({ values, closeModal }) => {
                                                              replace(values);
                                                              closeModal();
                                                            }}
                                                          >
                                                            {button}
                                                          </FinalDestinationDetails>
                                                        ),
                                                    },
                                                    {
                                                      icon: regular('trash-can'),
                                                      label: 'Delete',
                                                      onClick: () => remove(node),
                                                    },
                                                  ]}
                                                >
                                                  {({ open }) => (
                                                    <button
                                                      type='button'
                                                      onMouseEnter={() => setHoveredMenuButtonNodeId(node.id)}
                                                      onMouseLeave={() => setHoveredMenuButtonNodeId('')}
                                                      className={cn('flex hover:text-inherit', { 'text-zinc-400': !open })}
                                                    >
                                                      <FontAwesomeIcon className='h-4 aspect-square' icon={solid('ellipsis-vertical')} />
                                                    </button>
                                                  )}
                                                </Menu>
                                              </div>
                                            )}
                                          </div>
                                          {!isDeleted(node) && node?.type !== NodeType.FinalDestination && (
                                            <button
                                              type='button'
                                              className={cn(
                                                'flex flex-col gap-1.5 px-1.5 py-1 rounded-md',
                                                'border border-transparent',
                                                node?.type === NodeType.Warehouse ? 'bg-fuchsia-50' : 'bg-blue-50',
                                                'group/inner',
                                                {
                                                  invisible: !node,
                                                  [cn(
                                                    node?.type === NodeType.Warehouse ? 'hover:bg-fuchsia-200' : 'hover:bg-blue-200',
                                                    'hover:shadow-[0_0_2px_0_rgba(0,0,0,0.2)]',
                                                  )]: node && !dragState?.inProgress,
                                                  'group-hover/outer:bg-neutral-50':
                                                    node && !dragState?.inProgress && hoveredInnerViewButtonNodeId !== node.id,
                                                  'cursor-move': dragState?.inProgress,
                                                },
                                              )}
                                              onMouseEnter={() => setHoveredInnerViewButtonNodeId(node!.id)}
                                              onMouseLeave={() => setHoveredInnerViewButtonNodeId('')}
                                              onClick={(event) => {
                                                event.stopPropagation();
                                                savedScroll.current = {
                                                  left: scrollableRef.current!.scrollLeft,
                                                  top: scrollableRef.current!.scrollTop,
                                                };
                                                setInnerView({
                                                  name: InnerView.Storage,
                                                  nodeId: node!.id,
                                                });
                                              }}
                                            >
                                              <div className='flex gap-1.5 items-center'>
                                                <div
                                                  className={cn(
                                                    'rounded-2xl px-1.5 py-0.5 bg-white ',
                                                    node?.type === NodeType.Warehouse ? 'text-fuchsia-900' : 'text-blue-900',
                                                    {
                                                      'group-hover/outer:text-inherit':
                                                        node && !dragState?.inProgress && hoveredInnerViewButtonNodeId !== node.id,
                                                      [node?.type === NodeType.Warehouse
                                                        ? 'group-hover/inner:text-fuchsia-900'
                                                        : 'group-hover/inner:text-blue-900']: node && !dragState?.inProgress,
                                                    },
                                                  )}
                                                >
                                                  {node?.items.length ?? 0}
                                                </div>
                                                <div
                                                  className={cn({
                                                    'group-hover/outer:text-zinc-500':
                                                      node && !dragState?.inProgress && hoveredInnerViewButtonNodeId !== node.id,
                                                    'group-hover/inner:text-inherit': node && !dragState?.inProgress,
                                                  })}
                                                >
                                                  Stored item{(node?.items.length ?? 0) === 1 ? '' : 's'}
                                                </div>
                                              </div>
                                              {renderInnerViewBadges(node)}
                                            </button>
                                          )}
                                          {renderNodeBadges(node)}
                                        </div>
                                      </div>,
                                    );

                                  return (
                                    <>
                                      {sizeToPositions(formik.values.layoutGrids.distribution).map((position) =>
                                        render(
                                          position,
                                          sortAndFilterNodes([
                                            ...getWarehousesWithDeleted(formik, payload, readOnlyMode),
                                            ...getStoresWithDeleted(formik, payload, readOnlyMode),
                                            ...getFinalDestinationsWithDeleted(formik, payload, readOnlyMode),
                                          ]).find((node) => node.layoutPosition?.x === position.x && node.layoutPosition?.y === position.y),
                                        ),
                                      )}
                                      {sortAndFilterNodes([
                                        ...getWarehousesWithDeleted(formik, payload, readOnlyMode),
                                        ...getStoresWithDeleted(formik, payload, readOnlyMode),
                                        ...getFinalDestinationsWithDeleted(formik, payload, readOnlyMode),
                                      ])
                                        .filter(
                                          (node) =>
                                            !node.layoutPosition ||
                                            node.layoutPosition.x >= formik.values.layoutGrids.distribution.width ||
                                            node.layoutPosition.y >= formik.values.layoutGrids.distribution.height,
                                        )
                                        .map((node) => render(undefined, node))}
                                    </>
                                  );
                                })()
                              )}
                            </div>
                          </div>
                          {final && (
                            <>
                              <div className='flex flex-col rounded-lg bg-white'>
                                <div className='flex flex-col justify-center items-center gap-4 py-4 rounded-t-lg bg-[#E8EAF5]'>
                                  <div className='text-zinc-700 font-semibold'>Use</div>
                                  <div
                                    className={cn('flex gap-2', {
                                      invisible: readOnlyMode,
                                    })}
                                  >
                                    <ConsumptionLocationDetails
                                      payload={payload}
                                      readOnlyMode={readOnlyMode}
                                      onSave={({ values, closeModal }) => {
                                        addAll(values);
                                        closeModal();
                                      }}
                                    >
                                      <button
                                        type='button'
                                        className='group relative flex justify-center items-center gap-1 rounded-lg p-2 text-cyan-900 bg-cyan-50 shadow-[0_0_2px_0_rgba(0,0,0,0.2)] active:scale-95'
                                      >
                                        <FontAwesomeIcon icon={duotone('house-user')} className='group-hover:invisible' />
                                        <div className='group-hover:invisible'>Add consumption location</div>
                                        <FontAwesomeIcon icon={solid('plus')} className='absolute text-base hidden group-hover:block' />
                                      </button>
                                    </ConsumptionLocationDetails>
                                  </div>
                                </div>
                                <div ref={lifeCycleBoxRefs.current[LifeCycleStage.Use]} className='flex flex-col gap-10 p-6'>
                                  {getConsumptionLocationsWithDeleted(formik, payload, false).length === 0 && (
                                    <div className='text-zinc-400 text-center'>
                                      The region where your product gets consumed affects its impact. Add the area you want to assess, or
                                      even several if your product is sold in various locations!
                                    </div>
                                  )}
                                  {sortAndFilterNodes(getConsumptionLocationsWithDeleted(formik, payload, readOnlyMode))
                                    .flatMap((location) => [
                                      location,
                                      ...sortAndFilterNodes(getPackagingsWithDeleted(formik, payload, readOnlyMode))
                                        .slice(
                                          Math.floor(
                                            sortAndFilterNodes(getPackagingsWithDeleted(formik, payload, readOnlyMode)).length / 2,
                                          ),
                                        )
                                        .map((packaging) => ({
                                          id: `${location.id}.${packaging.id}`,
                                          displayName: packaging.displayName,
                                        })),
                                    ])
                                    .map((locationOrGap) => {
                                      const location = getConsumptionLocationsWithDeleted(formik, payload, readOnlyMode).some(
                                        ({ id }) => id === locationOrGap.id,
                                      )
                                        ? (locationOrGap as ConsumptionNode)
                                        : undefined;
                                      return (
                                        <ConsumptionLocationDetails
                                          key={locationOrGap.id}
                                          payload={payload}
                                          data={location}
                                          readOnlyMode={readOnlyMode}
                                          onSave={({ values, closeModal }) => {
                                            replace(values[0]);
                                            closeModal();
                                          }}
                                        >
                                          <div className={cn({ 'pointer-events-none': waiting || isDeleted(location) })}>
                                            <div
                                              ref={nodeRefs.current[locationOrGap.id]}
                                              className={cn(
                                                'relative text-zinc-700 cursor-pointer flex-1 flex flex-col gap-3 bg-white rounded-xl p-1.5',
                                                'transition',
                                                'group/outer',
                                                getBorderStyle(location),
                                                {
                                                  'hover:bg-cyan-50':
                                                    location &&
                                                    ![hoveredInnerViewButtonNodeId, hoveredMenuButtonNodeId].includes(location.id),
                                                  invisible: !location,
                                                },
                                              )}
                                            >
                                              <div className='flex gap-0.5 items-center justify-between'>
                                                <div
                                                  className={cn('flex gap-2 items-center truncate', {
                                                    'opacity-50': isDeleted(location),
                                                  })}
                                                >
                                                  <div className='flex justify-center items-center bg-cyan-50 text-cyan-900 rounded-md h-7 aspect-square'>
                                                    <FontAwesomeIcon icon={duotone('house-user')} />
                                                  </div>
                                                  <div className='font-semibold truncate'>{locationOrGap.displayName}</div>
                                                </div>
                                                {location && !isDeleted(location) && !readOnlyMode && (
                                                  <div onClick={(event) => event.stopPropagation()}>
                                                    <Menu
                                                      zIndex={20}
                                                      placement='bottom-start'
                                                      items={[
                                                        {
                                                          icon: regular('pencil'),
                                                          label: 'Edit',
                                                          modal: (button, onOpenChange) => (
                                                            <ConsumptionLocationDetails
                                                              onOpenChange={onOpenChange}
                                                              key={locationOrGap.id}
                                                              payload={payload}
                                                              data={location}
                                                              readOnlyMode={readOnlyMode}
                                                              onSave={({ values, closeModal }) => {
                                                                replace(values[0]);
                                                                closeModal();
                                                              }}
                                                            >
                                                              {button}
                                                            </ConsumptionLocationDetails>
                                                          ),
                                                        },
                                                        {
                                                          icon: regular('trash-can'),
                                                          label: 'Delete',
                                                          onClick: () => remove(location),
                                                        },
                                                      ]}
                                                    >
                                                      {({ open }) => (
                                                        <button
                                                          type='button'
                                                          onMouseEnter={() => setHoveredMenuButtonNodeId(location.id)}
                                                          onMouseLeave={() => setHoveredMenuButtonNodeId('')}
                                                          className={cn('flex hover:text-inherit', { 'text-zinc-400': !open })}
                                                        >
                                                          <FontAwesomeIcon
                                                            className='h-4 aspect-square'
                                                            icon={solid('ellipsis-vertical')}
                                                          />
                                                        </button>
                                                      )}
                                                    </Menu>
                                                  </div>
                                                )}
                                              </div>
                                              {location && !isDeleted(location) && (
                                                <button
                                                  type='button'
                                                  className={cn(
                                                    'flex gap-1.5 items-center bg-cyan-50 px-1.5 py-1 rounded-md',
                                                    'hover:bg-cyan-200 hover:shadow-[0_0_2px_0_rgba(0,0,0,0.2)]',
                                                    'border',
                                                    !readOnlyMode &&
                                                      location.steps.some((step) =>
                                                        hasValidationMessage(step, formik, {
                                                          ignoreLessImportant: ignoreLessImportantValidation,
                                                        }),
                                                      )
                                                      ? 'border-red-500'
                                                      : 'border-transparent',
                                                    'group/inner',
                                                    {
                                                      'group-hover/outer:bg-neutral-50': hoveredInnerViewButtonNodeId !== location.id,
                                                    },
                                                  )}
                                                  onMouseEnter={() => setHoveredInnerViewButtonNodeId(location.id)}
                                                  onMouseLeave={() => setHoveredInnerViewButtonNodeId('')}
                                                  onClick={(event) => {
                                                    event.stopPropagation();
                                                    savedScroll.current = {
                                                      left: scrollableRef.current!.scrollLeft,
                                                      top: scrollableRef.current!.scrollTop,
                                                    };
                                                    setInnerView({ name: InnerView.ConsumptionLocation, nodeId: location.id });
                                                  }}
                                                >
                                                  <div
                                                    className={cn(
                                                      'rounded-2xl px-1.5 py-0.5 bg-white text-cyan-900 group-hover/inner:text-cyan-900',
                                                      {
                                                        'group-hover/outer:text-inherit': hoveredInnerViewButtonNodeId !== location.id,
                                                      },
                                                    )}
                                                  >
                                                    {location.steps.length}
                                                  </div>
                                                  <div
                                                    className={cn('group-hover/inner:text-inherit', {
                                                      'group-hover/outer:text-zinc-500': hoveredInnerViewButtonNodeId !== location.id,
                                                    })}
                                                  >
                                                    Preparation step{location.steps.length === 1 ? '' : 's'}
                                                  </div>
                                                </button>
                                              )}
                                              {renderNodeBadges(location)}
                                            </div>
                                          </div>
                                        </ConsumptionLocationDetails>
                                      );
                                    })}
                                </div>
                              </div>
                              <div className='flex flex-col rounded-lg bg-white'>
                                <div className='flex flex-col justify-center items-center gap-4 py-4 rounded-t-lg bg-[#E8EAF5]'>
                                  <div className='text-zinc-700 font-semibold'>End of Life</div>
                                  <div className='flex px-2 h-8 items-center text-center text-zinc-500'>
                                    This section will be filled out automatically.
                                  </div>
                                </div>
                                <div ref={lifeCycleBoxRefs.current[LifeCycleStage.EndOfLife]} className='flex flex-col gap-5 p-6'>
                                  {getDisposalsWithDeleted(formik, payload, false).length === 0 && (
                                    <div className='text-zinc-400 text-center'>
                                      End of life impact only considers the disposal of packaging. As such, elements will automatically
                                      appear when you add packaging as raw materials.
                                    </div>
                                  )}
                                  {sortBy(sortAndFilterNodes(getDisposalsWithDeleted(formik, payload, readOnlyMode)), [
                                    ({ id }) =>
                                      (
                                        getConsumptionLocationsWithDeleted(formik, payload, readOnlyMode).find(({ edges }) =>
                                          edges.includes(id),
                                        ) ?? getConsumptionLocationsFromAll(payload.product.nodes).find(({ edges }) => edges.includes(id))
                                      )?.displayName,
                                    ({ displayName }) => displayName,
                                  ]).map((disposal) => (
                                    <DisposalDetails
                                      key={disposal.id}
                                      payload={payload}
                                      data={disposal}
                                      readOnlyMode={readOnlyMode}
                                      onSave={({ values, closeModal }) => {
                                        replace(values);
                                        closeModal();
                                      }}
                                    >
                                      <div className={cn({ 'pointer-events-none': waiting || isDeleted(disposal) })}>
                                        <div
                                          ref={nodeRefs.current[disposal.id]}
                                          className={cn(
                                            'text-zinc-700 cursor-pointer flex-1 flex flex-col gap-3 bg-white rounded-xl p-1.5',
                                            'transition hover:bg-slate-50',
                                            getBorderStyle(disposal),
                                          )}
                                        >
                                          <div
                                            className={cn('flex gap-2 items-center', {
                                              'opacity-50': isDeleted(disposal),
                                            })}
                                          >
                                            <div className='flex justify-center items-center bg-slate-50 text-slate-900 rounded-md h-7 aspect-square'>
                                              <FontAwesomeIcon icon={duotone('dumpster')} />
                                            </div>
                                            <div className='font-semibold truncate'>{disposal.displayName}</div>
                                          </div>
                                          {renderNodeBadges(disposal)}
                                        </div>
                                      </div>
                                    </DisposalDetails>
                                  ))}
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                    {getTransportsWithDeleted(formik, payload, readOnlyMode).map((transport) => (
                      <TransportLink
                        key={transport.id}
                        payload={payload}
                        disabled={!!dragState}
                        nodeRefs={nodeRefs}
                        lifeCycleBoxRefs={lifeCycleBoxRefs}
                        scrollableRef={scrollableRef}
                        fromId={
                          (getPrimaryAndMaterialSuppliersWithDeleted(formik, payload, readOnlyMode).find(({ edges }) =>
                            edges.includes(transport.id),
                          ) ??
                            getPrimaryAndMaterialSuppliersFromAll(payload.product.nodes).find(({ edges }) => edges.includes(transport.id)))!
                            .id
                        }
                        toId={transport.edges[0]}
                        transport={transport}
                        ignoreLessImportantValidation={ignoreLessImportantValidation}
                        readOnlyMode={readOnlyMode}
                        onSave={({ values, fromId, closeModal }) => {
                          addReplaceTransport(values, fromId);
                          closeModal();
                        }}
                        onDelete={() => {
                          remove(transport);
                        }}
                      />
                    ))}
                    {uniqBy(
                      sortAndFilterNodes([
                        ...getConsumptionLocationsWithDeleted(formik, payload, readOnlyMode),
                        ...getConsumptionLocationsFromAll(payload.product.nodes),
                      ]).flatMap((location) => location.edges.map((edgeId) => ({ ...location, edgeId }))),
                      ({ id, edgeId }) => `${id}/${edgeId}`,
                    ).map((location) => (
                      <TransportLink
                        key={location.edgeId}
                        payload={payload}
                        disabled={!!dragState}
                        nodeRefs={nodeRefs}
                        lifeCycleBoxRefs={lifeCycleBoxRefs}
                        scrollableRef={scrollableRef}
                        fromId={location.id}
                        toId={location.edgeId}
                        ignoreLessImportantValidation={ignoreLessImportantValidation}
                        readOnlyMode={readOnlyMode}
                      />
                    ))}
                  </>
                )}
                <div className='h-36 shrink-0'></div>
              </div>
              <div className={padding}></div>
            </>
          );
        })()}
      </div>
      {!innerView && !readOnlyMode && (
        <button type='button' className='group absolute bottom-4 z-20 ml-6 rounded-full border shadow-[0px_1px_10px_rgba(79,_0,_255,_0.3)]'>
          <TransportDetails
            payload={props.payload}
            readOnlyMode={readOnlyMode}
            onSave={({ values, fromId, closeModal }) => {
              addReplaceTransport(values, fromId);
              closeModal();
            }}
          >
            <div className='flex items-center rounded-full relative bg-brand'>
              <div className='flex justify-center items-center bg-brand h-12 aspect-square rounded-full z-10 group-hover:cursor-pointer'>
                <FontAwesomeIcon className='h-5 aspect-square text-white' icon={duotone('truck')} />
              </div>
              <div
                className={cn(
                  'flex items-center justify-center bg-brand',
                  '-left-6 transition-width w-0 group-hover:w-40 h-12 duration-300',
                  'relative rounded-r-full whitespace-nowrap',
                  'group-hover:cursor-pointer',
                )}
              >
                <div className='ml-8 text-white w-0 group-hover:w-full overflow-hidden font-semibold transition-opacity delay-100 duration-200 opacity-0 group-hover:opacity-100'>
                  New transport link
                </div>
              </div>
            </div>
          </TransportDetails>
        </button>
      )}
    </div>
  );
};
